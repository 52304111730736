import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { BusEditableProps } from "../interfaces/Editable/BusEditableDetails";
import { BusReadOnlyProps } from "../interfaces/BusReadOnlyDetails";
import _ from "lodash";
import {
	busReadOnlyModel,
	busReadOnlyModelBuilder,
} from "../models/BusReadOnlyModel";
import {
	busEditableModel,
	busEditableModelBuilder,
} from "../models/Editable/BusEditableModel";
import { saleInvoiceModelBuilder } from "../models/SaleInvoiceModel";
import { invoiceExportationModelBuilder } from "../models/InvoiceExportationModel";
import { invoiceInstructionModelBuilder } from "../models/InvoiceInstructionModel";
import { invoiceInstructionExportationModelBuilder } from "../models/InvoiceInstructionExportationModel";
import { reservationAvailabilityModelBuilder } from "../models/ReservationAvailabilityModel";
import RootStore from "./RootStore";
import { newRootStore } from "../utils/StoreUtils";
import { invoicingDeliveryMarketCompanyModelBuilder } from "../models/InvoicingDeliveryMarketCompanyModel";

configure({ enforceActions: "always" });

/* Store start */
class BusStore {
	@observable menuKey: string = "";
	@observable readOnlyDetails: BusReadOnlyProps = busReadOnlyModel;
	@observable editableDetails: BusEditableProps = busEditableModel;
	@observable initialEditableDetails: BusEditableProps = busEditableModel;
	@observable hasChanges: boolean = false;

	rootStore: RootStore = newRootStore();

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		makeObservable(this);
	}

	@action resetStore = (menuKey: string) => {
		this.readOnlyDetails = busReadOnlyModel;
		this.editableDetails = busEditableModel;
		this.initialEditableDetails = busEditableModel;
		this.hasChanges = false;
		this.menuKey = menuKey;
	};

	@action setEditableDetailsFieldByKeyOnChange = (
		fieldKey: keyof BusEditableProps,
		newValue: string & Date
	) => {
		this.editableDetails[fieldKey] = newValue ?? "";
		this.checkChanges();
	};

	@action setReadOnlyDetails = (data: any) => {
		this.readOnlyDetails = busReadOnlyModelBuilder({
			...this.readOnlyDetails,
			...data,
		});
	};

	@action setEditableDetails = (data: any) => {
		this.editableDetails = busEditableModelBuilder({
			...this.editableDetails,
			...data,
		});
		this.initialEditableDetails = busEditableModelBuilder({
			...this.initialEditableDetails,
			...data,
		});
	};

	@action private checkChanges = () => {
		this.hasChanges = !_.isEqual(
			this.initialEditableDetails,
			this.editableDetails
		);
		this.rootStore.commonStore.setHasChanges();
	};

	@action loadDetails = async (
		id: string,
		menuKey: string,
		userDisplayName: string
	) => {
		this.rootStore.commonStore.resetAllStores(id, menuKey);
		if (!id || !menuKey) return;
		await this.api.busService.getDetails(id, menuKey).then((response) => {
			this.setReadOnlyDetails(response.data);
			this.setEditableDetails(response.data);
			this.rootStore.commonStore.setStore(response.data.id, menuKey);
			this.rootStore.commonStore.setBaseFields(userDisplayName);
		});
	};

	@action loadVariants = async () => {
		const id = this.readOnlyDetails.id;
		if (!id || !this.menuKey) return;
		await this.api.busService
			.getDetailsVariants(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.variants = response.data ?? [];
			});
	};

	@action loadVariantsRespec = async () => {
		const id = this.readOnlyDetails.id;
		if (!id || !this.menuKey) return;
		await this.api.orderService
			.getDetailsVariantsRespecList(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.variantsRespec = response.data ?? [];
			});
	};

	@action loadSaleInvoice = async () => {
		const id = this.readOnlyDetails.id;
		if (!id || !this.menuKey) return;
		await this.api.orderInvoiceService
			.getSaleInvoiceByOrderId(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.saleInvoice = saleInvoiceModelBuilder(
					response.data
				);
			});
	};

	@action loadLocalizationInvoices = async () => {
		const id = this.readOnlyDetails.id;
		if (!id || !this.menuKey) return;
		await this.api.orderInvoiceService
			.getLocalizationInvoicesByOrderId(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.localizationInvoices = response.data ?? [];
			});
	};

	@action loadReservationAvailability = async () => {
		const id = this.readOnlyDetails.id;
		if (!id || !this.menuKey) return;
		await this.api.orderService
			.getReservationAvailabilityDetails(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.reservationAvailability =
					reservationAvailabilityModelBuilder(response.data);
			});
	};

	@action loadInvoiceExportation = async () => {
		const id = this.readOnlyDetails.id;
		if (!id || !this.menuKey) return;
		await this.api.orderInvoiceService
			.getInvoiceExportationByOrderId(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.invoiceExportation =
					invoiceExportationModelBuilder(response.data);
			});
	};

	@action loadInvoicingDeliveryMarketCompany = async () => {
		const id = this.readOnlyDetails.id;
		if (!id || !this.menuKey) return;
		await this.api.orderInvoiceService
			.getInvoicingDeliveryMarketCompanyByOrderId(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.invoicingDeliveryMarketCompany =
					invoicingDeliveryMarketCompanyModelBuilder(response.data);
			});
	};

	@action loadInvoiceInstruction = async (userCanSeeAllInfo: boolean) => {
		const id = this.readOnlyDetails.id;
		if (!id || !this.menuKey) return;
		if (userCanSeeAllInfo) await this.loadAllInvoiceInstruction(id);
		else await this.loadMinimalInvoiceInstruction(id);
	};

	@action loadAllInvoiceInstruction = async (id: string) => {
		await this.api.orderInvoiceInstructionService
			.getByOrderId(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.invoiceInstruction =
					invoiceInstructionModelBuilder(response.data);
			});
	};

	@action loadMinimalInvoiceInstruction = async (id: string) => {
		await this.api.orderInvoiceInstructionService
			.getMinimalInformationByOrderId(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.invoiceInstruction =
					invoiceInstructionModelBuilder(response.data);
			});
	};

	@action loadInvoiceInstructionExportation = async (
		userCanSeeAllInfo: boolean
	) => {
		const id = this.readOnlyDetails.id;
		if (!id || !this.menuKey) return;
		if (userCanSeeAllInfo) await this.loadAllInvoiceInstructionExportation(id);
		else await this.loadMinimalInvoiceInstructionExportation(id);
	};

	@action loadAllInvoiceInstructionExportation = async (id: string) => {
		await this.api.orderInvoiceInstructionService
			.getExportationByOrderId(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.invoiceInstructionExportation =
					invoiceInstructionExportationModelBuilder(response.data);
			});
	};

	@action loadMinimalInvoiceInstructionExportation = async (id: string) => {
		await this.api.orderInvoiceInstructionService
			.getExportationMinimalInformationByOrderId(id, this.menuKey)
			.then((response) => {
				this.readOnlyDetails.invoiceInstructionExportation =
					invoiceInstructionExportationModelBuilder(response.data);
			});
	};

	@action update = async () => {
		await this.rootStore.commonStore.update();

		if (!this.hasChanges) return;

		if (this.hasZoneChanges())
			await this.api.busService.updateZone(
				this.editableDetails.id,
				this.editableDetails.zoneId
			);

		return await this.api.busService.update(this.editableDetails, this.menuKey);
	};

	private hasZoneChanges = (): boolean =>
		this.editableDetails.zoneId !== this.initialEditableDetails.zoneId;
}
/* Store end */

export default BusStore;

import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import RootStore from "./RootStore";
import { newRootStore } from "../utils/StoreUtils";

configure({ enforceActions: "always" });

/* Store start */
class CommonStore {
	@observable orderId: string = "";
	@observable menuKey: string = "";
	@observable menuType: string = "";
	@observable userDisplayName: string = "";
	@observable hasChanges: boolean = false;

	rootStore: RootStore = newRootStore();

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		makeObservable(this);
	}

	@action validateBaseRequest = () => {
		if (!this.orderId || !this.menuKey)
			throw console.log("Invalid Id or Menu Key");
	};

	@action resetAllStores = (id: string, menuKey: string) => {
		this.setStore(id, menuKey);
		this.rootStore.busStore.resetStore(menuKey);
		this.rootStore.truckStore.resetStore(menuKey);
		this.rootStore.volvoManagementStore.setStore();
	};

	@action setStore = (id: string, menuKey: string) => {
		this.orderId = id;
		this.menuKey = menuKey;
		this.menuType = "";
		this.userDisplayName = "";
		this.hasChanges = false;
	};

	@action setBaseFields = (userDisplayName: string) => {
		this.userDisplayName = userDisplayName;
	};

	@action setHasChanges = () => {
		this.hasChanges =
			this.rootStore.busStore.hasChanges ||
			this.rootStore.truckStore.hasChanges ||
			this.rootStore.volvoManagementStore.hasChanges;
	};

	@action update = async () => {
		await this.rootStore.volvoManagementStore.update();
	};
}
/* Store end */
export default CommonStore;

import React from "react";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Box, Chip, Tooltip } from "@mui/material";
import DateTimeToString from "../../../../../utils/DateTimeToString";
import { LastUpdateChipProps } from "../../interfaces/Utils/LastUpdateChip";
import { observer } from "mobx-react";

const LastUpdateChip: React.FC<LastUpdateChipProps> = (details) => {
	const { t } = useTranslation("order");
	return (
		<Box display="flex" justifyContent="flex-end" width={"90%"}>
			{details.lastUpdateEventTypeName && details.lastUpdateDate && (
				<Tooltip
					title={t("tooltips.lastEvent").toString()}
					aria-label={t("tooltips.lastEvent")}
				>
					<Chip
						variant="outlined"
						size="small"
						label={`${t(
							`eventType.${_.camelCase(details.lastUpdateEventTypeName)}`
						)} :${" "}
            ${DateTimeToString(
							details.lastUpdateDate,
							"DD/MM/YYYY HH:mm:ss",
							"-",
							true
						)}`}
					/>
				</Tooltip>
			)}
		</Box>
	);
};

export default observer(LastUpdateChip);

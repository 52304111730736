import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Chip, Grid, Skeleton } from "@mui/material";
import { InvoicingDeliverySectionProps } from "../../interfaces/Sections/InvoicingDeliverySection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import _ from "lodash";

const InvoicingDeliverySection: React.FC<InvoicingDeliverySectionProps> = ({
	readOnlyFields,
	loadSaleInvoice,
}) => {
	const { t } = useTranslation(["order", "general"]);
	const [loading, setLoading] = React.useState(true);

	const fetchSaleInvoice = React.useCallback(async () => {
		await loadSaleInvoice().finally(() => setLoading(false));
	}, [loadSaleInvoice]);

	React.useEffect(() => {
		fetchSaleInvoice();
	}, [fetchSaleInvoice]);

	const saleInvoiceStatus = [
		t("details.sections.invoicingDelivery.status.emited"),
		t("details.sections.invoicingDelivery.status.canceled"),
		t("details.sections.invoicingDelivery.status.returned"),
	];
	const invoiceSaleIsAntecipadedBadge = () => {
		if (readOnlyFields.saleInvoice.isAntecipaded === true)
			return (
				<Chip
					size="small"
					label={t("details.sections.invoicingDelivery.antecipaded")}
					color="primary"
					style={{ margin: 4 }}
				/>
			);
		return;
	};
	return (
		<>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : (
				<>
					<Grid className="accordionSubTitle" item xs={12}>
						{t("details.sections.invoicingDelivery.title")}
						{invoiceSaleIsAntecipadedBadge()}
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.invoicingDelivery.invoiceNumber")}
							value={readOnlyFields.saleInvoice.number}
						/>
					</Grid>
					<Grid item xs={4}>
						<DateForm
							label={t("details.sections.invoicingDelivery.invoiceDate")}
							value={readOnlyFields.saleInvoice.date}
						/>
					</Grid>
					<Grid item xs={4}>
						<DateForm
							label={t("details.sections.invoicingDelivery.gateDate")}
							value={readOnlyFields.gateDate}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.invoicingDelivery.status.label")}
							value={
								_.isNumber(readOnlyFields.invoiceSaleStatus)
									? saleInvoiceStatus[readOnlyFields.invoiceSaleStatus]
									: ""
							}
						/>
					</Grid>
					<Grid item xs={4}>
						<DateForm
							label={t("details.sections.invoicingDelivery.invoiceCancelDate")}
							value={readOnlyFields.saleInvoice.cancelDate}
						/>
					</Grid>
					<Grid item xs={4}>
						<DateForm
							label={t("details.sections.invoicingDelivery.deliveryDate")}
							value={readOnlyFields.deliveryDate}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextForm
							label={t("details.sections.invoicingDelivery.invoiceCustomer")}
							value={readOnlyFields.saleInvoice.customerName}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextForm
							label={t(
								"details.sections.invoicingDelivery.invoiceDealerRegion"
							)}
							value={readOnlyFields.saleInvoice.dealerRegion}
						/>
					</Grid>
				</>
			)}
		</>
	);
};

export default observer(InvoicingDeliverySection);

import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../../../../apis/CtadmvenApi";
import { IPreFilterCommonOption } from "../interfaces/IPreFilter";
import { getFilterObject } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterDealerStore {
	@observable rootStore;
	@observable loading: boolean = false;
	@observable preFilter: Array<string> = [];
	@observable preFilterOptions: Array<IPreFilterCommonOption> = [];

	constructor(
		rootStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action private setDealerOptions = (data: any) => {
		this.preFilterOptions = [];

		if (!data) return;

		const arrayOfKeyValues = Object.entries(data);
		this.preFilterOptions = arrayOfKeyValues.map((m) => ({
			value: m[0],
			label: m[1] as string,
		}));
	};

	@action reset = () => {
		this.preFilter = [];
		this.preFilterOptions = [];
	};

	@action clean = () => (this.preFilter = []);

	@action get = () =>
		this.preFilter.length === 0 ||
		this.preFilter.length === this.preFilterOptions.length
			? []
			: this.preFilter.map((d: string) =>
					getFilterObject("managementDetail.dealerId", d, "eq")
				);

	@action set = (dealers: Array<string>) => {
		this.preFilter = dealers;
	};

	@action getOptions = async () => {
		if (this.preFilterOptions.length > 0) return;

		this.loading = true;
		await this.api.dealerService
			.getDictionaryIdNameByCountry("ARG")
			.then((rs: any) => {
				this.setDealerOptions(toJS(rs.data));
			})
			.finally(() => (this.loading = false));
	};

	@action getFilterDescriptor(orFiltersGroups: Array<Array<FilterDescriptor>>) {
		const dealerFilters = this.get();

		if (dealerFilters) orFiltersGroups.push(dealerFilters);
	}

	@action hasPreFilter = () => this.preFilter.length > 0;
}

export default PreFilterDealerStore;

import {
	Checkbox,
	DialogActions,
	DialogContent,
	FormControlLabel,
	FormGroup,
	Grid,
} from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { useEditRegionStore } from "../stores/EditRegionStore";
import markets from "../models/markets.json";
import { regionsEditModel } from "../models/RegionsEditPropsModel";
import React from "react";
import { toast } from "react-toastify";
import "../styles/regions.css";
import ApplyButton from "../../../../../components/Dialog/Buttons/ApplyButton";
import CancelButton from "../../../../../components/Dialog/Buttons/CancelButton";
import { Save } from "@mui/icons-material";
import DialogForm from "../../../../../components/Dialog/DialogForm";

const EditForm: React.FC = () => {
	const { t } = useTranslation("general");

	const {
		isFormVisible,
		actionLabel,
		setFormVisible,
		regionsEdit,
		createOrUpdate,
		dataItemChanged,
		setDataItemChanged,
		oldDataItemChanged,
		setOldDataItemChanged,
		regionsInitial,
	} = useEditRegionStore();

	const [hasError, setError] = React.useState<boolean>(false);

	const toggleDialog = () => {
		setFormVisible(!isFormVisible);
	};

	const handleClose = () => {
		cleanDataItemChanged();
		toggleDialog();
		setError(false);
	};

	const setValue = (value: string) => {
		if (value && value.length > 3) return;

		if (
			(regionsEdit.filter((x) => x.value.toUpperCase() === value.toUpperCase())
				.length > 0 ||
				regionsInitial.filter(
					(x) => x.value.toUpperCase() === value.toUpperCase()
				).length > 0) &&
			oldDataItemChanged.value.toUpperCase() !== value.toUpperCase()
		)
			setError(true);
		else setError(false);

		setDataItemChanged({
			...dataItemChanged,
			value: value.toUpperCase(),
		});
	};

	const setMarkets = (market: string) => {
		let marketsArray = dataItemChanged.markets
			.split("_")
			.filter((x) => x !== "");

		if (dataItemChanged.markets.includes(market)) {
			marketsArray = marketsArray.filter((x) => x !== market);
		} else {
			// Logic to make sure with the 2 types will be BR_EXP
			if (marketsArray.includes(markets[1])) marketsArray.unshift(market);
			else marketsArray.push(market);
		}

		setDataItemChanged({
			...dataItemChanged,
			markets: marketsArray.join("_"),
		});
	};

	const cleanDataItemChanged = () => {
		setDataItemChanged(regionsEditModel);
		setOldDataItemChanged(regionsEditModel);
	};

	const handleSubmit = () => {
		let markets = dataItemChanged.markets;

		if (markets.length > 1)
			createOrUpdate(dataItemChanged)
				.finally(() => {
					toggleDialog();
					cleanDataItemChanged();
				})
				.then(() => {
					if (actionLabel === "addRecord")
						toast.success(
							t("addRecordWithSuccess", { ns: "general" }).toString()
						);
					else
						toast.success(
							t("editRecordWithSuccess", { ns: "general" }).toString()
						);
				})
				.catch((err) => toast.error(err.response.data));
	};

	return (
		<DialogForm
			id="dialog-regions-edition"
			open={isFormVisible}
			title={t(`${actionLabel}`, { name: t("region") })}
			onClose={handleClose}
		>
			<DialogContent>
				<Grid container spacing={2} overflow="none">
					<Grid item xs={12}>
						<TextForm
							editable
							id={"value"}
							label={t("region")}
							value={dataItemChanged?.value}
							onChange={(e) => setValue(e.target.value)}
							type="text"
						/>
						{hasError && (
							<span className="error-message">
								{t("validator.fieldDuplicated")}
							</span>
						)}
					</Grid>
					<Grid item xs={12}>
						<FormGroup>
							{markets.map((market, i) => {
								return (
									<FormControlLabel
										control={
											<Checkbox
												checked={dataItemChanged?.markets.includes(market)}
												color="secondary"
												onChange={() => setMarkets(market)}
											/>
										}
										label={market}
										key={i}
									/>
								);
							})}
						</FormGroup>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<CancelButton
					id="dialog-regions-edition"
					onCancel={handleClose}
					title={t("cancel")}
				/>
				<ApplyButton
					id="dialog-regions-edition"
					onSubmit={handleSubmit}
					title={t("saveChanges", { ns: "general" })}
					startIcon={<Save />}
					disabled={
						hasError ||
						dataItemChanged.value.length !== 3 ||
						dataItemChanged.markets.length === 0
					}
				/>
			</DialogActions>
		</DialogForm>
	);
};

export default observer(EditForm);

import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../../components/AccordionCard/AccordionCard";
import { VehicleAccordionProps } from "../../../interfaces/Accordions/Europe/VehicleAccordion";
import ModelSection from "../../Sections/Europe/ModelSection";
import VariantsSection from "../../Sections/Europe/VariantsSection";
import VehicleIdentificationSection from "../../Sections/VehicleIdentificationSection";

const VehicleAccordion: React.FC<VehicleAccordionProps> = (details) => {
	const { t } = useTranslation("order");
	return (
		<AccordionCard
			id="vehicleAccordion"
			title={t("details.accordions.vehicle")}
		>
			<ModelSection {...details.modelDetails} />
			<VehicleIdentificationSection {...details.vehicleIdentificationDetails} />
			<VariantsSection {...details.variantsDetails} />
		</AccordionCard>
	);
};

export default observer(VehicleAccordion);

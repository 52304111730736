import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import { InvoiceInstructionExportationSectionProps } from "../../interfaces/Sections/InvoiceInstructionExportationSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { InvoiceInstructionExpComissionProps } from "../../interfaces/InvoiceInstructionExportationDetails";
import OverlineTextCentered from "../../../../../components/OverlineTextCentered/OverlineTextCentered";
import { parseNumberToDecimal } from "../../../../../utils/NumberOperations";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { groupsAllowedToSeeAllInvoiceInstructionExportationSection } from "../../../../../constants/AuthGroups/orderDetails";

const InvoiceInstructionExportationSection: React.FC<
	InvoiceInstructionExportationSectionProps
> = ({ userIsOwner, readOnlyFields, loadInvoiceInstructionExportation }) => {
	const { t } = useTranslation(["order", "general"]);

	const { isAuthorizedGroup } = useAuthStore();
	const userCanSeeAllInfo =
		userIsOwner ||
		isAuthorizedGroup(
			groupsAllowedToSeeAllInvoiceInstructionExportationSection
		);

	const [loading, setLoading] = React.useState(true);

	const fetchInvoiceInstructionExportation = React.useCallback(async () => {
		await loadInvoiceInstructionExportation(userCanSeeAllInfo).finally(() =>
			setLoading(false)
		);
	}, [loadInvoiceInstructionExportation, userCanSeeAllInfo]);

	React.useEffect(() => {
		fetchInvoiceInstructionExportation();
	}, [fetchInvoiceInstructionExportation]);

	const saleType = t(
		"details.sections.invoiceInstructionExportation.saleType.options." +
			readOnlyFields.invoiceInstructionExportation.saleType
	);
	const total =
		readOnlyFields.invoiceInstructionExportation.importerValue +
		readOnlyFields.invoiceInstructionExportation.shippingValue +
		readOnlyFields.invoiceInstructionExportation.insuranceValue +
		readOnlyFields.invoiceInstructionExportation.customsValue;

	return (
		<>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : (
				<>
					<Grid className="accordionSubTitle" item xs={12}>
						{t("details.sections.invoiceInstructionExportation.title")}
					</Grid>
					<Grid item xs={6}>
						<TextForm
							label={t(
								"details.sections.invoiceInstructionExportation.dealerNameWithCode"
							)}
							value={
								readOnlyFields.invoiceInstructionExportation.dealerNameWithCode
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextForm
							label={t(
								"details.sections.invoiceInstructionExportation.dealerNameCountry"
							)}
							value={
								readOnlyFields.invoiceInstructionExportation.dealerNameCountry
							}
						/>
					</Grid>
					{userCanSeeAllInfo && (
						<>
							<Grid item xs={3}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.currency"
									)}
									value={readOnlyFields.invoiceInstructionExportation.currency}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.shippingMethod"
									)}
									value={
										readOnlyFields.invoiceInstructionExportation.shippingMethod
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.carrier"
									)}
									value={readOnlyFields.invoiceInstructionExportation.carrier}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.paymentTerms"
									)}
									value={
										readOnlyFields.invoiceInstructionExportation.paymentTerms
									}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.incoterms"
									)}
									value={readOnlyFields.invoiceInstructionExportation.incoterms}
								/>
							</Grid>
							<Grid item xs={2}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.exportationType"
									)}
									value={
										readOnlyFields.invoiceInstructionExportation.exportationType
									}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.saleType.label"
									)}
									value={saleType}
								/>
							</Grid>
							<Grid className="accordionSubTitle" item xs={12}>
								{t(
									"details.sections.invoiceInstructionExportation.titleValues"
								)}
							</Grid>
							<Grid item xs={3}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.importerValue"
									)}
									value={parseNumberToDecimal(
										readOnlyFields.invoiceInstructionExportation.importerValue,
										2
									)}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.shippingValue"
									)}
									value={parseNumberToDecimal(
										readOnlyFields.invoiceInstructionExportation.shippingValue,
										2
									)}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.insuranceValue"
									)}
									value={parseNumberToDecimal(
										readOnlyFields.invoiceInstructionExportation.insuranceValue,
										2
									)}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.customsValue"
									)}
									value={parseNumberToDecimal(
										readOnlyFields.invoiceInstructionExportation.customsValue,
										2
									)}
								/>
							</Grid>
							<Grid item xs={3}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.total"
									)}
									value={parseNumberToDecimal(total, 2)}
								/>
							</Grid>
							<Grid className="accordionSubTitle" item xs={12}>
								{t(
									"details.sections.invoiceInstructionExportation.titleComissions"
								)}
							</Grid>
							{readOnlyFields.invoiceInstructionExportation.comissions
								.length === 0 && (
								<Grid item xs={12}>
									<OverlineTextCentered>
										{t("noRecordsAvailable", { ns: "general" })}
									</OverlineTextCentered>
								</Grid>
							)}
							{readOnlyFields.invoiceInstructionExportation.comissions.map(
								(
									comission: InvoiceInstructionExpComissionProps,
									idx: number
								) => (
									<Grid item key={`comission-${idx}`} xs={6}>
										<Grid item xs={6}>
											<TextForm
												label={t(
													"details.sections.invoiceInstructionExportation.amount"
												)}
												value={comission.amount}
											/>
										</Grid>
										<Grid item xs={6}>
											<TextForm
												label={t(
													"details.sections.invoiceInstructionExportation.beneficiaryNameWithCode"
												)}
												value={comission.beneficiaryNameWithCode}
											/>
										</Grid>
									</Grid>
								)
							)}
							<Grid className="accordionSubTitle" item xs={12}>
								{t(
									"details.sections.invoiceInstructionExportation.titleApprovals"
								)}
							</Grid>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.mktApproved"
									)}
									value={
										readOnlyFields.invoiceInstructionExportation.mktApproved
											? "OK"
											: "NOK"
									}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextForm
									label={t(
										"details.sections.invoiceInstructionExportation.financialApproved"
									)}
									value={
										readOnlyFields.invoiceInstructionExportation
											?.financialApproved
											? "OK"
											: "NOK"
									}
								/>
							</Grid>
						</>
					)}
				</>
			)}
		</>
	);
};

export default observer(InvoiceInstructionExportationSection);

import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";

import GeneralAccordion from "./Accordions/Europe/GeneralAccordion";
import VehicleAccordion from "./Accordions/Europe/VehicleAccordion";
import ProductionAccordion from "./Accordions/Europe/ProductionAccordion";
import InvoiceDeliveryAccordion from "./Accordions/Europe/InvoiceDeliveryAccordion";

import { useEuropeStore } from "../stores/EuropeStore";
import SaveBar from "../../../../components/SaveBar/SaveBar";

import {
	getMenuTitleByMenuKey,
	getMenuTruckByRoute,
} from "../../../../constants/Menus/OrderMenuUtils";
import NoContentResult from "../../../../components/msal/NoContentResult";
import { ModelStateProps } from "../interfaces/Utils/Validation";

const EuropeIndex: React.FC = () => {
	const { t } = useTranslation(["ctadmven", "menu", "general"]);
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = React.useState(true);

	const {
		readOnlyDetails,
		editableDetails,
		setEditableFieldByKeyOnChange,
		hasFormChanges,

		loadDetails,

		update,
	} = useEuropeStore();

	const splittedHash = window.location.hash.split("/");
	const menuRoute = splittedHash[splittedHash.length - 2] ?? "";
	const menuKey = getMenuTruckByRoute(menuRoute);

	const fetchDetails = React.useCallback(async () => {
		setLoading(true);
		await loadDetails(id ?? "", menuKey).finally(() => setLoading(false));
	}, [id, loadDetails, menuKey]);

	React.useEffect(() => {
		fetchDetails();
	}, [fetchDetails]);

	const handleErrors = (data: string | ModelStateProps) => {
		if (typeof data === "string") toast.error(data);
		else {
			toast.error(data.title);
		}
	};

	const handleSubmit = async () => {
		setLoading(true);
		await update()
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => handleErrors(err.message))
			.finally(() => fetchDetails());
	};

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t(getMenuTitleByMenuKey(menuKey), { ns: "menu" })}`,
			href: `#/${menuRoute}`,
			main: false,
		},
		{
			title: t("orderBookTrucks.details", { ns: "menu" }),
			main: true,
		},
	];

	return (
		<>
			<LoadingPage loading={loading} />
			{!loading && readOnlyDetails.factoryOrder && (
				<>
					<TitleBox
						breadcrumbs={breadcrumbs}
						pageTitle={t("orderBookTrucks.pageTitle", {
							ns: "menu",
							ob: t("orderBookTrucks.details", { ns: "menu" }),
						})}
					/>
					<Box p={1}>
						<Grid container spacing={2}>
							<Grid item xs>
								<Grid container spacing={2}>
									<GeneralAccordion
										generalDetails={{ readOnlyFields: { ...readOnlyDetails } }}
										customerDetails={{
											readOnlyFields: { ...readOnlyDetails },
											editableFields: { ...editableDetails },
											setFieldByKeyOnChange: setEditableFieldByKeyOnChange,
											countryName: readOnlyDetails.countryName,
										}}
									/>
									<VehicleAccordion
										modelDetails={{ readOnlyFields: { ...readOnlyDetails } }}
										vehicleIdentificationDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										variantsDetails={{ readOnlyFields: { ...readOnlyDetails } }}
									/>
									<ProductionAccordion
										productionDatesDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
									/>
									<InvoiceDeliveryAccordion
										exportationInvoiceProps={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										exportationLocationProps={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										invoicingDeliveryMarketCompanyProps={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										menuKey={menuKey}
									/>
								</Grid>
								<SaveBar
									onResetClick={() => fetchDetails()}
									resetLabel={t("undoChanges", { ns: "general" })}
									onSaveClick={() => handleSubmit()}
									saveLabel={t("saveChanges", { ns: "general" })}
									hasFormChanges={hasFormChanges}
								/>
							</Grid>
						</Grid>
					</Box>
					<ToastContainer />
				</>
			)}
			{!loading && !readOnlyDetails.factoryOrder && <NoContentResult />}
		</>
	);
};

export default observer(EuropeIndex);

import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../../components/AccordionCard/AccordionCard";
import { InvoiceDeliveryAccordionProps } from "../../../interfaces/Accordions/Europe/InvoiceDeliveryAccordion";
import ExportationInvoiceSection from "../../Sections/Europe/ExportationInvoiceSection";
import ExportationLocationSection from "../../Sections/Europe/ExportationLocationSection";
import ReservationExportSection from "../../Sections/Europe/ReservationExportSection";
import InvoicingDeliveryMarketCompanySection from "../../Sections/Europe/InvoicingDeliveryMarketCompanySection";

const InvoiceDeliveryAccordion: React.FC<InvoiceDeliveryAccordionProps> = (
	details
) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="invoiceDeliveryAccordion"
			title={t("details.accordions.invoicingDelivery")}
		>
			<ReservationExportSection {...details.reservationExportDetails} />
			<ExportationLocationSection {...details.exportationLocationProps} />
			<ExportationInvoiceSection {...details.exportationInvoiceProps} />
			<InvoicingDeliveryMarketCompanySection
				{...details.invoicingDeliveryMarketCompanyProps}
			/>
		</AccordionCard>
	);
};

export default observer(InvoiceDeliveryAccordion);

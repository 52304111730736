import * as React from "react";
import { toJS } from "mobx";
import { observer } from "mobx-react";
import { DataGridApi } from "../../../../../components/kendo/DataGridApi/DataGridApi";
import { gridConst } from "../../../../../constants/gridConst";
import { IColumnProps } from "../../../../../components/kendo/DataGridApi/interfaces/IColumnProps";
import { useStores } from "../../stores/RootStore";
import { ToastContainer } from "react-toastify";
import { Box, Paper, Stack } from "@mui/material";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { green, yellow } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import {
	EUROPE_FACTORY_ORDERBOOK,
	FD_MKT_COMPANY,
	FD_MKT_COMPANY_BUS,
	FD_MKT_COMPANY_EUROPE,
} from "../../../../../constants/Menus/OrderMenuUtils";

type HomePageGrid = {
	columns: Array<IColumnProps>;
	calculateWidth?: boolean;
};

const MenuGrid: React.FC<HomePageGrid> = ({ columns, calculateWidth }) => {
	const { t } = useTranslation(["order", "general"]);
	const { allRecords, menu, refresh } = useStores().listStore;
	const { filters } = useStores().filterStore;
	const [displayCaptions, setDisplayCaptions] = useState<boolean>(true);
	const ordersWithIF = yellow[100];
	const invoicedOrders = green[100];

	React.useEffect(() => {
		const notApplicableMenusGridHighlight = [
			EUROPE_FACTORY_ORDERBOOK,
			FD_MKT_COMPANY,
			FD_MKT_COMPANY_BUS,
			FD_MKT_COMPANY_EUROPE,
		];
		setDisplayCaptions(!notApplicableMenusGridHighlight.includes(menu));
	}, [menu]);

	const rowRender = (trElement: any, props: any) => {
		let rowColor = {};

		if (props.dataItem.invoiceSaleDate && displayCaptions)
			rowColor = invoicedOrders;
		else if (
			(props.dataItem.invoiceInstructionCreationDate !== null ||
				props.dataItem.hasInvoiceInstructionExp === true) &&
			!props.dataItem.invoiceSaleDate &&
			displayCaptions
		) {
			rowColor = ordersWithIF;
		} else {
			rowColor = "#ffffff";
		}

		const trProps = {
			style: { backgroundColor: rowColor },
		};

		return React.cloneElement(
			trElement,
			{
				...trProps,
			},
			trElement.props.children
		);
	};

	function gridCaptions() {
		if (displayCaptions)
			return (
				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						mt: 2,
					}}
				>
					<Paper>
						<Stack direction="row" spacing={2} padding={1}>
							<Box>{t("gridCaptions.title")}:</Box>
							<Stack direction="row" spacing={2}>
								<Stack direction="row" spacing={0.5}>
									<Brightness1Icon
										htmlColor={invoicedOrders}
										fontSize="small"
									></Brightness1Icon>
									<Box>{t("gridCaptions.invoicedOrders")}</Box>
								</Stack>
								<Stack direction="row" spacing={0.5}>
									<Brightness1Icon
										htmlColor={ordersWithIF}
										fontSize="small"
									></Brightness1Icon>
									<Box>{t("gridCaptions.orderWithIF")}</Box>
								</Stack>
							</Stack>
						</Stack>
					</Paper>
				</Box>
			);
	}

	return (
		<>
			<DataGridApi
				columns={columns}
				fetchData={allRecords}
				pageable={gridConst.pageable}
				pageSize={gridConst.pageSize}
				filterBy={toJS(filters)}
				rowHeight={10}
				reorderable={true}
				resizable={true}
				className="k-grid-lg"
				calculateWidth={calculateWidth ?? true}
				rowRender={rowRender}
				refresh={refresh}
			/>
			<ToastContainer />
			{gridCaptions()}
		</>
	);
};

export default observer(MenuGrid);

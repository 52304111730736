import BasePreFilterStore from "../../../../../../components/PreFilter/Stores/BasePreFilterStore";
import { getFilterObjectArrayOfStringKeyBySize } from "../../../../../../utils/FilterOperations";
import RootStore from "../../../stores/RootStore";
import { configure } from "mobx";

configure({ enforceActions: "always" });

class PreFilterBusinessControlVehicleStore extends BasePreFilterStore {
	filterKey = "order.businessControlVehicle";
    filterSize = 51;
    operator = "contains";

	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	getSpecificFilter(): any {
		return getFilterObjectArrayOfStringKeyBySize(
			this.filterSize,
			this.preFilter,
			this.filterKey,
			this.operator
		);
	};
}

export default PreFilterBusinessControlVehicleStore;

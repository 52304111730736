import { action, configure, makeObservable, observable } from "mobx";
import { State } from "@progress/kendo-data-query";
import RootStore from "./RootStore";
import {
	BusMenus,
	OrderBookMenuType,
	TOTAL,
	TruckMenus,
	EuropeMenus,
} from "../../../../constants/Menus/OrderMenuUtils";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { CultureType, LineNullableType } from "../../../../utils/GenericTypes";
import { CsvOptionsProps } from "../interfaces/CsvOptionsProps";
import { toast } from "react-toastify";
import {
	CSV_DISABLED_BUSES_ORDERS,
	CSV_DISABLED_EUROPE_ORDERS,
	CSV_DISABLED_TRUCKS_ORDERS,
} from "../../../../constants/csvButtonStorage";
import { getCsvButtonTimer } from "../../../../utils/CheckCsvTimer";
import { CsvQueueStatus } from "../../../../utils/enums/CsvQueueStatusEnum";

configure({ enforceActions: "always" });

class ListStore {
	@observable rootStore;
	@observable menu: OrderBookMenuType = TOTAL;
	@observable loading: boolean = true;
	@observable includeVariants: boolean = false;
	@observable listTotalResult: number = 0;
	@observable lastDataState: State | undefined;
	@observable csvLineFilter: LineNullableType = "";
	@observable refresh: number = 0;

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		this.reset();
		makeObservable(this);
	}

	@action setRefresh = () => this.refresh++;

	@action reset = () => {
		this.menu = TOTAL;
		this.loading = true;
		this.rootStore.filterStore.cleanAllFilters();
	};

	@action setIsButtonCsvDisabledTruck = (value: Date) =>
		localStorage.setItem(CSV_DISABLED_TRUCKS_ORDERS, value.toString());
	@action setIsButtonCsvDisabledBuses = (value: Date) =>
		localStorage.setItem(CSV_DISABLED_BUSES_ORDERS, value.toString());
	@action setIsButtonCsvDisabledEurope = (value: Date) =>
		localStorage.setItem(CSV_DISABLED_EUROPE_ORDERS, value.toString());

	@action setLoading = (value: boolean) => (this.loading = value);

	@action setIncludeVariants = (value: boolean) =>
		(this.includeVariants = value);

	@action setCsvLineFilter = (value: LineNullableType) =>
		(this.csvLineFilter = value);

	@action setMenu = (value: OrderBookMenuType) => (this.menu = value);

	@action createDataStateObj = (dataState: State) =>
		({ ...dataState }) as State;

	@action allRecords = async (dataState: State) => {
		this.setLastDataState(this.createDataStateObj(dataState));
		if (!this.lastDataState) return;

		this.setLoading(true);

		if (EuropeMenus.some((europeMenu) => europeMenu === this.menu))
			return this.api.europeFactoryService
				.listPaginated(this.lastDataState, this.menu)
				.then((json) => Object.assign({ selected: false }, json.data))
				.catch((err) => toast.error(err.response.data))
				.finally(() => this.setLoading(false));

		if (TruckMenus.some((truckMenu) => truckMenu === this.menu))
			return this.api.orderService
				.listPaginated(this.lastDataState, this.menu)
				.then((json) => {
					this.setListTotalResult(json.data.total);
					return Object.assign({ selected: false }, json.data);
				})
				.catch((err) => toast.error(err.response.data))
				.finally(() => this.setLoading(false));

		if (BusMenus.some((busMenu) => busMenu === this.menu))
			return this.api.busService
				.listPaginated(this.lastDataState, this.menu)
				.then((json) => {
					this.setListTotalResult(json.data.total);
					return Object.assign({ selected: false }, json.data);
				})
				.catch((err) => toast.error(err.response.data))
				.finally(() => this.setLoading(false));
	};

	@action csvExport = async (culture: CultureType): Promise<CsvQueueStatus> => {
		if (!this.lastDataState) {
			return CsvQueueStatus.AlreadyInQueue;
		}

		this.setLoading(true);

		const csvOptions: CsvOptionsProps = {
			includeVariants: this.includeVariants,
			culture: culture,
		};

		let status = CsvQueueStatus.Error;

		if (EuropeMenus.some((europeMenu) => europeMenu === this.menu)) {
			this.setIsButtonCsvDisabledEurope(getCsvButtonTimer());
			const result = await this.api.europeFactoryService.csvExport(
				this.lastDataState,
				this.menu,
				csvOptions
			);
			status = result.data;
		} else if (BusMenus.some((busMenu) => busMenu === this.menu)) {
			this.setIsButtonCsvDisabledBuses(getCsvButtonTimer());
			const result = await this.api.busService.csvExport(
				this.lastDataState,
				this.menu,
				csvOptions
			);
			status = result.data;
		} else {
			this.setIsButtonCsvDisabledTruck(getCsvButtonTimer());
			const result = await this.api.orderService.csvExport(
				this.lastDataState,
				this.menu,
				csvOptions
			);
			status = result.data;
		}

		this.setLoading(false);

		return status;
	};

	@action private setListTotalResult = (value: number) =>
		(this.listTotalResult = value);

	@action private setLastDataState = (value: State) =>
		(this.lastDataState = value);
}

export default ListStore;

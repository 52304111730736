import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import {
	BUS_FREE_UNIT_VCI,
	BUS_FREE_UNIT_VCI_VDB,
} from "../../../../../constants/Menus/OrderMenuUtils";
import { BRA_DEALER_TNE_TNO } from "../../../../../constants/AuthGroups/authGroups";
import { groupsAllowedToSeeRegionPreFilter } from "../../../../../constants/AuthGroups/orderListBus";
import PreFilter from "../../../../../components/PreFilter/PreFilter";
import { useTranslation } from "react-i18next";
import { CHASSI_FO } from "../../../../../constants/chassiAoOmFoConst";
import LineSequenceDate from "../../../../../components/PreFilter/containers/LineSequenceDatePreFilter";
import ChassiAOPreFilter from "../../../../../components/PreFilter/containers/ChassiAOPreFilter";
import RegionPreFilter from "../../../../../components/PreFilter/containers/RegionPreFilter";
import BusApplicationPreFilter, {
	BUSAPP_PRE_FILTER_SM,
} from "../../../../../components/PreFilter/containers/BusApplicationPreFilter";
import AsWeekPreFilter, {
	ASWEEK_PRE_FILTER_SM,
} from "../../../../../components/PreFilter/containers/AsWeekPreFilter";
import CommercialModelPreFilter, {
	COMMERCIALMODEL_PRE_FILTER_MD,
} from "../../../../../components/PreFilter/containers/CommercialModelPreFilter";
import CustomerSASPreFilter from "../../../../../components/PreFilter/containers/CustomerSASPreFilter";
import CustomerOMPreFilter from "../../../../../components/PreFilter/containers/CustomerOMPreFilter";
import { GRID_MAX_SIZE } from "../../../../../constants/gridConst";

const BusBatchEdition: React.FC = () => {
	const { getOptions: getOptionsRegion, setIsTracbelUser } =
		useStores().preFilterRegionStore;
	const { isAuthorizedGroup } = useAuthStore();
	const { menu } = useStores().listStore;
	const { t } = useTranslation("order");

	const {
		preFilterAsWeekStore,
		preFilterChassiAOStore,
		preFilterCommercialModelStore,
		preFilterCustomerStoreOM,
		preFilterCustomerStoreSAS,
		preFilterRegionStore,
		preFilterLineSequenceDateStore,
		preFilterBusApplicationStore,
	} = useStores();

	const {
		applyFilters,
		hasPreFiltersApplied,
		hasFormatError,
		filters,
		removeFilter,
		cleanSpecificPreFilters,
		onUndo,
	} = useStores().filterStore;

	setIsTracbelUser(isAuthorizedGroup([BRA_DEALER_TNE_TNO]));

	const shouldShowRegionPreFilter =
		isAuthorizedGroup(groupsAllowedToSeeRegionPreFilter) ||
		menu === BUS_FREE_UNIT_VCI ||
		menu === BUS_FREE_UNIT_VCI_VDB;

	React.useEffect(() => {
		if (shouldShowRegionPreFilter) {
			getOptionsRegion(menu);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldShowRegionPreFilter]);

	React.useEffect(() => {
		return () => {
			cleanSpecificPreFilters();
		};
	}, []);

	let commercialModelSm =
		GRID_MAX_SIZE - (BUSAPP_PRE_FILTER_SM + ASWEEK_PRE_FILTER_SM);
	if (shouldShowRegionPreFilter) {
		commercialModelSm = GRID_MAX_SIZE;
	}

	return (
		<PreFilter
			id="orderbook-pre-filter-edition"
			title={t("preFilters.quickFilter", { ns: "order" })}
			onUndo={onUndo}
			onSubmit={applyFilters}
			hasPreFiltersApplied={hasPreFiltersApplied}
			hasFormatError={hasFormatError}
			filters={filters}
			chassiType={CHASSI_FO}
			removeFilter={removeFilter}
		>
			<ChassiAOPreFilter
				vehicleType="Bus"
				type={CHASSI_FO}
				preFilterStore={preFilterChassiAOStore}
			/>
			<LineSequenceDate preFilterStore={preFilterLineSequenceDateStore} />
			{shouldShowRegionPreFilter && (
				<RegionPreFilter preFilterStore={preFilterRegionStore} sm={6} xl={4} />
			)}
			<BusApplicationPreFilter preFilterStore={preFilterBusApplicationStore} />
			<AsWeekPreFilter preFilterStore={preFilterAsWeekStore} />
			<CommercialModelPreFilter
				preFilterStore={preFilterCommercialModelStore}
				sm={commercialModelSm}
			/>
			<CustomerSASPreFilter
				preFilterStore={preFilterCustomerStoreSAS}
				md={GRID_MAX_SIZE - COMMERCIALMODEL_PRE_FILTER_MD}
			/>
			<CustomerOMPreFilter preFilterStore={preFilterCustomerStoreOM} />
		</PreFilter>
	);
};

export default observer(BusBatchEdition);

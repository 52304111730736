import { action, configure, makeObservable, observable, toJS } from "mobx";
import {
	AutoCompleteOptionProps,
	SelectOptionProps,
} from "../../../../utils/GenericInterfaces";
import { BatchDataProps } from "../interfaces/BatchDataProps";
import { batchDataModel } from "../models/batchDataModel";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import _ from "lodash";
import RootStore from "./RootStore";
import { newRootStore } from "../utils/StoreUtils";
import { GUID_EMPTY } from "../../../../app/constants/config";

configure({ enforceActions: "always" });

class QuotationBatchEditionStore {
	rootStore: RootStore = newRootStore();
	@observable batchData: BatchDataProps = batchDataModel;
	@observable salesmanOptions: Array<SelectOptionProps> = [];
	@observable customerOptions: Array<SelectOptionProps> = [];
	@observable formHasChanges: boolean = false;

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		makeObservable(this);
	}

	@action resetData = () => {
		this.resetBatchData();
		this.setFormHasChanges(false);
	};

	@action setLoading = (value: boolean) => {
		this.rootStore.listStore.loading = value;
	};

	@action private resetBatchData = () => (this.batchData = batchDataModel);

	@action setFormHasChanges = (formHasChanges: boolean) =>
		(this.formHasChanges = formHasChanges);

	@action checkAndSetFormHasChanges = () => {
		const batchDataHasChanges = !_.isEqual(batchDataModel, this.batchData);
		this.setFormHasChanges(batchDataHasChanges);
	};

	@action setQuotationOptions = <K extends keyof BatchDataProps>(
		fieldKey: K,
		newValue: BatchDataProps[K]
	) => {
		if (fieldKey === "salesmanId" && newValue.trim() === "")
			newValue = GUID_EMPTY;

		const _filter: BatchDataProps = Object.assign({}, this.batchData);
		_filter[fieldKey] = newValue;
		this.batchData = _filter;
		this.checkAndSetFormHasChanges();
	};

	@action updateBatchData = () => {
		this.setLoading(true);
		const batchData = toJS(this.batchData);
		return this.api.quotationService
			.updateBatch(this.rootStore.listStore.lastDataState, batchData)
			.then(() => this.resetData())
			.finally(() => {
				this.rootStore.listStore.setRefresh();
			});
	};

	@action loadSalesmanOptions = async () => {
		if (this.salesmanOptions.length > 0) return;

		await this.api.salesmanService.getSalesmanOptions().then((rs: any) => {
			if (rs.data)
				this.salesmanOptions = toJS(rs.data).map(
					(m: any) =>
						({
							label: m.displayName,
							value: m.id,
							searchValue: `${m.displayName}/${m.email}/${m.tbCode}`,
						}) as AutoCompleteOptionProps
				);
		});
	};

	@action loadCustomerOptions = async () => {
		if (this.customerOptions.length > 0) return;

		await this.api.customerService
			.getCustomerByCountry("CHL")
			.then((rs: any) => {
				if (rs.data)
					this.customerOptions = toJS(rs.data).map(
						(m: any) =>
							({
								label: m.name,
								value: m.code,
								searchValue: `${m.name}/${m.code}`,
							}) as AutoCompleteOptionProps
					);
			});
	};
}

export default QuotationBatchEditionStore;

import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { hasMinValueRequiredValidator } from "../../../../../utils/FormValidators";
import { useQuotationDetailStore } from "../../store/QuotationDetailStore";
import { QuotationReadOnlyDetails } from "../../interfaces/QuotationReadOnlyDetails";
import { PriceAndPaymentSectionProps } from "../../interfaces/Sections/PriceAndPaymentSection";
import { parseNumberToDecimal } from "../../../../../utils/NumberOperations";

interface SectionProps {
	editable: boolean;
	readOnlyDetails: QuotationReadOnlyDetails;
	setPriceAndPayment: any;
	discount: number;
	totalPrice: number;
}

const PriceAndPaymentSection: React.FC<PriceAndPaymentSectionProps> = ({
	editable,
}) => {
	const { setPriceAndPayment, readOnlyDetails, editableDetails } =
		useQuotationDetailStore();

	const { currency } = readOnlyDetails;

	const { priceAndPayment } = editableDetails;

	if (currency === "USD")
		return (
			<PriceAndPaymentUSDSection
				editable={editable}
				readOnlyDetails={readOnlyDetails}
				setPriceAndPayment={setPriceAndPayment}
				{...priceAndPayment}
			/>
		);

	return (
		<PriceAndPaymentCLPSection
			editable={editable}
			readOnlyDetails={readOnlyDetails}
			setPriceAndPayment={setPriceAndPayment}
			{...priceAndPayment}
		/>
	);
};

const PriceAndPaymentCLPSection: React.FC<SectionProps> = ({
	editable,
	readOnlyDetails,
	setPriceAndPayment,
	discount,
	totalPrice,
}) => {
	return (
		<PriceAndPaymentGeneralSection
			readOnlyDetails={{
				...readOnlyDetails,
				currency: "CLP",
				currentExchangeRate: 1,
			}}
			editable={editable}
			setPriceAndPayment={setPriceAndPayment}
			discount={discount}
			totalPrice={totalPrice}
		/>
	);
};

const PriceAndPaymentUSDSection: React.FC<SectionProps> = ({
	editable,
	readOnlyDetails,
	setPriceAndPayment,
	discount,
	totalPrice,
}) => {
	const { t } = useTranslation("quotation");
	const { currentExchangeRate } = readOnlyDetails;
	return (
		<>
			<PriceAndPaymentGeneralSection
				readOnlyDetails={{
					...readOnlyDetails,
					currency: "USD",
					currentExchangeRate: 1,
				}}
				editable={editable}
				setPriceAndPayment={setPriceAndPayment}
				discount={discount}
				totalPrice={totalPrice}
			/>
			<PriceAndPaymentGeneralSection
				readOnlyDetails={{
					...readOnlyDetails,
					currency: "CLP",
				}}
				editable={false}
				setPriceAndPayment={setPriceAndPayment}
				discount={discount}
				totalPrice={totalPrice}
			/>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.priceAndPayment.currentExchangeRate")}
					value={currentExchangeRate}
					isCurrency
				/>
			</Grid>
		</>
	);
};

const PriceAndPaymentGeneralSection: React.FC<SectionProps> = ({
	editable,
	readOnlyDetails,
	setPriceAndPayment,
	discount,
	totalPrice,
}) => {
	const { t } = useTranslation("quotation");

	const formValidators = (value: any) =>
		!hasMinValueRequiredValidator(value, 1)
			? t("validator.minNumber", { min: 1, ns: "general" })
			: "";

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.priceAndPayment.title") +
					` ${readOnlyDetails.currency}`}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.priceAndPayment.priceByUnit")}
					value={parseNumberToDecimal(
						readOnlyDetails.priceByUnit * readOnlyDetails.currentExchangeRate,
						2
					)}
					isCurrency
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.priceAndPayment.quantity")}
					value={readOnlyDetails.quantity}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.priceAndPayment.totalTax")}
					value={parseNumberToDecimal(
						readOnlyDetails.totalTaxAmount *
							readOnlyDetails.currentExchangeRate,
						2
					)}
					isCurrency
				/>
			</Grid>
			{editable ? (
				<>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.priceAndPayment.discount")}
							value={discount}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
								_?: boolean,
								__?: string
							) => setPriceAndPayment("discount", +e.target.value, "")}
							type="number"
							isCurrency
							inputProps={{ min: 1 }}
							name="discount"
							editable={editable}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.priceAndPayment.totalPrice")}
							value={totalPrice}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
								_?: boolean,
								error?: string
							) => {
								setPriceAndPayment("totalPrice", +e.target.value, error ?? "");
							}}
							type="number"
							isCurrency
							inputProps={{ min: 1 }}
							name="totalPrice"
							editable={editable}
							validator={(value) => formValidators(value)}
						/>
					</Grid>
				</>
			) : (
				<>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.priceAndPayment.discount")}
							value={parseNumberToDecimal(
								discount * readOnlyDetails.currentExchangeRate,
								2
							)}
							isCurrency
						/>
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.priceAndPayment.totalPrice")}
							value={parseNumberToDecimal(
								totalPrice * readOnlyDetails.currentExchangeRate,
								2
							)}
							isCurrency
						/>
					</Grid>
				</>
			)}
		</>
	);
};

export default observer(PriceAndPaymentSection);

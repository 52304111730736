import { State } from "@progress/kendo-data-query";
import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import {
	FollowUpMenuType,
	FOLLOWUP_MENU_ARG_BUS,
	FOLLOWUP_MENU_ARG_TRUCK,
} from "../../../../constants/Menus/FollowUpMenuUtils";
import { FollowUpProps } from "../interfaces/FollowUpProps";
import RootStore from "./RootStore";
import { CultureType } from "../../../../utils/GenericTypes";
import { CsvOptionsProps } from "../../../Order/List/interfaces/CsvOptionsProps";
import {
	CSV_DISABLED_BUSES_FOLLOW_UP_ARG,
	CSV_DISABLED_TRUCKS_FOLLOW_UP_ARG,
} from "../../../../constants/csvButtonStorage";
import { getCsvButtonTimer } from "../../../../utils/CheckCsvTimer";
import { CsvQueueStatus } from "../../../../utils/enums/CsvQueueStatusEnum";
import {
	summaryTruckModelBuilder,
	SummaryTruckModelProps,
} from "../models/Summary/SummaryTruckModel";
import {
	summaryModelBuilder,
	summaryModelInitial,
	SummaryModelProps,
} from "../models/Summary/SummaryModel";
import {
	progressSummaryArrayModelBuilder,
	ProgressSummaryModelProps,
} from "../models/Summary/ProgressSummaryModel";

configure({ enforceActions: "always" });

class ListStore {
	@observable rootStore;
	@observable menu: FollowUpMenuType = FOLLOWUP_MENU_ARG_TRUCK;
	@observable followUp: Array<FollowUpProps> = [];
	@observable loading: boolean = false;
	@observable lastDataState: State | undefined;
	@observable includeVariants: boolean = false;
	@observable listTotalResult: number = 0;
	@observable refresh: number = 0;
	@observable summaryTruck: Array<SummaryTruckModelProps> = [];
	@observable loadingSummaryTruck: boolean = false;
	@observable summaryBus: SummaryModelProps = summaryModelInitial;
	@observable loadingSummaryBus: boolean = false;
	@observable progressSummaryTrucks: Array<ProgressSummaryModelProps> = [];
	@observable loadingProgressSummaryTrucks: boolean = false;
	@observable progressSummaryBus: Array<ProgressSummaryModelProps> = [];
	@observable loadingProgressSummaryBus: boolean = false;

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.menu = FOLLOWUP_MENU_ARG_TRUCK;
		this.loading = true;
	};

	@action setIncludeVariants = (value: boolean) =>
		(this.includeVariants = value);

	@action setMenu = (value: FollowUpMenuType) => (this.menu = value);

	@action setLoading = (value: boolean) => (this.loading = value);
	@action setRefresh = () => this.refresh++;

	@action setIsButtonCsvDisabledTruck = (value: Date) =>
		localStorage.setItem(CSV_DISABLED_TRUCKS_FOLLOW_UP_ARG, value.toString());
	@action setIsButtonCsvDisabledBuses = (value: Date) =>
		localStorage.setItem(CSV_DISABLED_BUSES_FOLLOW_UP_ARG, value.toString());

	@action setLoadingSummaryTruck = (value: boolean) =>
		(this.loadingSummaryTruck = value);
	@action setSummaryTruck = (value: Array<any>) =>
		(this.summaryTruck = summaryTruckModelBuilder(value));

	@action setLoadingSummaryBus = (value: boolean) =>
		(this.loadingSummaryBus = value);
	@action setSummaryBus = (value: Array<any>) =>
		(this.summaryBus = summaryModelBuilder(value));

	@action setLoadingProgressSummaryTrucks = (value: boolean) => {
		this.loadingProgressSummaryTrucks = value;
	};
	@action setProgressSummaryTrucks = (value: Array<any>) => {
		this.progressSummaryTrucks = progressSummaryArrayModelBuilder(value);
	};

	@action setLoadingProgressSummaryBus = (value: boolean) =>
		(this.loadingProgressSummaryBus = value);
	@action setProgressSummaryBus = (value: Array<any>) =>
		(this.progressSummaryBus = progressSummaryArrayModelBuilder(value));

	@action allRecords = async (dataState: State) => {
		this.setLastDataState(this.createDataStateObj(dataState));
		if (!this.lastDataState) return;

		this.setLoading(true);

		if (this.menu === FOLLOWUP_MENU_ARG_TRUCK)
			return await this.api.followUpArgService
				.listPaginated(this.lastDataState)
				.then((json) => {
					this.setListTotalResult(json.data.total);
					return Object.assign({ selected: false }, json.data);
				})
				.finally(() => this.setLoading(false));

		if (this.menu === FOLLOWUP_MENU_ARG_BUS)
			return await this.api.followUpArgService
				.listBusPaginated(this.lastDataState)
				.then((json) => {
					this.setListTotalResult(json.data.total);
					return this.getFormattedData(json);
				})
				.finally(() => this.setLoading(false));
	};

	@action getSummaryTruck = async () => {
		this.setLoadingSummaryTruck(true);
		await this.api.followUpArgService
			.getSummaryTruck()
			.then((json) => this.setSummaryTruck(json.data))
			.finally(() => this.setLoadingSummaryTruck(false));
	};

	@action getSummaryBus = async () => {
		this.setLoadingSummaryBus(true);
		await this.api.followUpArgService
			.getSummaryBus()
			.then((json) => this.setSummaryBus(json.data))
			.finally(() => this.setLoadingSummaryBus(false));
	};

	@action getProgressSummaryTrucks = async () => {
		this.setLoadingProgressSummaryTrucks(true);
		await this.api.followUpArgService
			.getProgressSummary("Truck")
			.then((json) => this.setProgressSummaryTrucks(json.data))
			.finally(() => this.setLoadingProgressSummaryTrucks(false));
	};

	@action getProgressSummaryBus = async () => {
		this.setLoadingProgressSummaryBus(true);
		await this.api.followUpArgService
			.getProgressSummary("Bus")
			.then((json) => this.setProgressSummaryBus(json.data))
			.finally(() => this.setLoadingProgressSummaryBus(false));
	};

	getFormattedData = (json: any) => {
		json.data.data = json?.data?.data?.map((x: any) =>
			x.order.assemblyOrder.length > 3 &&
			x.order.assemblyOrder.startsWith("000")
				? {
						...x,
						order: {
							...x.order,
							assemblyOrder: x.order.assemblyOrder.slice(3),
						},
					}
				: x
		);

		return Object.assign({ selected: false }, json.data);
	};

	@action createDataStateObj = (dataState: State) =>
		({ ...dataState }) as State;

	@action private setLastDataState = (value: State) =>
		(this.lastDataState = value);

	@action csvExport = async (culture: CultureType): Promise<CsvQueueStatus> => {
		if (!this.lastDataState) {
			return CsvQueueStatus.AddedToQueue;
		}

		this.setLoading(true);

		const csvOptions: CsvOptionsProps = {
			includeVariants: this.includeVariants,
			culture: culture,
		};

		let status = CsvQueueStatus.AddedToQueue;

		if (this.menu === FOLLOWUP_MENU_ARG_TRUCK) {
			this.setIsButtonCsvDisabledTruck(getCsvButtonTimer());
			const result = await this.api.followUpArgService.csvExport(
				this.lastDataState,
				csvOptions
			);
			status = result.data;
		} else if (this.menu === FOLLOWUP_MENU_ARG_BUS) {
			this.setIsButtonCsvDisabledBuses(getCsvButtonTimer());
			const result = await this.api.followUpArgService.csvExportBus(
				this.lastDataState,
				csvOptions
			);
			status = result.data;
		}

		this.setLoading(false);

		return status;
	};

	@action private setListTotalResult = (value: number) =>
		(this.listTotalResult = value);
}

export default ListStore;

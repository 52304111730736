import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import { InvoicingDeliveryMarketCompanySectionProps } from "../../interfaces/Sections/InvoicingDeliveryMarketCompanySection";
import OverlineTextCentered from "../../../../../components/OverlineTextCentered/OverlineTextCentered";

const InvoicingDeliveryMarketCompanySection: React.FC<
	InvoicingDeliveryMarketCompanySectionProps
> = ({ readOnlyFields, loadInvoicingDeliveryMarketCompany }) => {
	const { t } = useTranslation(["order", "general"]);
	const [loading, setLoading] = React.useState(true);

	const fetchInvoicingDeliveryMarketCompany = React.useCallback(async () => {
		await loadInvoicingDeliveryMarketCompany().finally(() => setLoading(false));
	}, [loadInvoicingDeliveryMarketCompany]);

	React.useEffect(() => {
		fetchInvoicingDeliveryMarketCompany();
	}, [fetchInvoicingDeliveryMarketCompany]);

	return (
		<>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : (
				<>
					<Grid className="accordionSubTitle" item xs={12}>
						{t("details.sections.invoicingDeliveryMarketCompany.title")}
					</Grid>
					{readOnlyFields.invoicedNumber || readOnlyFields.deliveredNumber ? (
						<>
							{readOnlyFields.invoicedNumber && (
								<>
									<Grid item xs={4}>
										<TextForm
											label={t(
												"details.sections.invoicingDeliveryMarketCompany.documentNumber"
											)}
											value={readOnlyFields.invoicedNumber}
										/>
									</Grid>
									<Grid item xs={4}>
										<DateForm
											label={t(
												"details.sections.invoicingDeliveryMarketCompany.documentDate"
											)}
											value={readOnlyFields.invoicedDate}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextForm
											label={t(
												"details.sections.invoicingDeliveryMarketCompany.documentType.label"
											)}
											value={t(
												"details.sections.invoicingDeliveryMarketCompany.documentType.invoiced"
											)}
										/>
									</Grid>
								</>
							)}
							{readOnlyFields.deliveredNumber && (
								<>
									<Grid item xs={4}>
										<TextForm
											label={t(
												"details.sections.invoicingDeliveryMarketCompany.documentNumber"
											)}
											value={readOnlyFields.deliveredNumber}
										/>
									</Grid>
									<Grid item xs={4}>
										<DateForm
											label={t(
												"details.sections.invoicingDeliveryMarketCompany.documentDate"
											)}
											value={readOnlyFields.deliveredDate}
										/>
									</Grid>
									<Grid item xs={4}>
										<TextForm
											label={t(
												"details.sections.invoicingDeliveryMarketCompany.documentType.label"
											)}
											value={t(
												"details.sections.invoicingDeliveryMarketCompany.documentType.delivered"
											)}
										/>
									</Grid>
								</>
							)}
						</>
					) : (
						<Grid item xs={12}>
							<OverlineTextCentered>
								{t("noRecordsAvailable", { ns: "general" })}
							</OverlineTextCentered>
						</Grid>
					)}
				</>
			)}
		</>
	);
};

export default observer(InvoicingDeliveryMarketCompanySection);

import { State } from "@progress/kendo-data-query";
import { action, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import RootStore from "./RootStore";
import { CsvOptionsProps } from "../../../Order/List/interfaces/CsvOptionsProps";
import { CultureType } from "../../../../utils/GenericTypes";
import { CTADMVEN_FOLLOWUP_CHL } from "../../../../constants/routes";
import { CSV_DISABLED_FOLLOW_UP_CHL } from "../../../../constants/csvButtonStorage";
import { getCsvButtonTimer } from "../../../../utils/CheckCsvTimer";
import { CsvQueueStatus } from "../../../../utils/enums/CsvQueueStatusEnum";

class ListStore {
	@observable rootStore;
	@observable menu: string = CTADMVEN_FOLLOWUP_CHL;
	@observable loading: boolean = false;
	@observable lastDataState: State | undefined;
	@observable includeVariants: boolean = false;
	@observable listTotalResult: number = 0;
	@observable refresh: number = 0;

	constructor(
		mainStore: RootStore,
		private api: CtadmvenApi
	) {
		this.rootStore = mainStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.rootStore.filterStore.cleanAllFilters();
		this.loading = true;
	};

	@action setIncludeVariants = (value: boolean) =>
		(this.includeVariants = value);

	@action setIsButtonCsvDisabled = (value: Date) =>
		localStorage.setItem(CSV_DISABLED_FOLLOW_UP_CHL, value.toString());

	@action setRefresh = (value: number) => this.refresh++;

	@action setLoading = (value: boolean) => (this.loading = value);

	@action createDataStateObj = (dataState: State) =>
		({ ...dataState }) as State;

	@action private setLastDataState = (value: State) =>
		(this.lastDataState = value);

	@action private setListTotalResult = (value: number) =>
		(this.listTotalResult = value);

	@action getAllRecords = async (dataState: State) => {
		this.setLastDataState(this.createDataStateObj(dataState));
		if (!this.lastDataState) return;

		this.setLoading(true);

		return await this.api.followUpChlService
			.listPaginated(this.lastDataState)
			.then((json) => {
				this.setListTotalResult(json.data.total);
				return Object.assign({ selected: false }, json.data);
			})
			.finally(() => this.setLoading(false));
	};

	@action csvExport = async (culture: CultureType): Promise<CsvQueueStatus> => {
		if (!this.lastDataState) {
			return CsvQueueStatus.AlreadyInQueue;
		}

		this.setIsButtonCsvDisabled(getCsvButtonTimer());

		this.setLoading(true);

		const csvOptions: CsvOptionsProps = {
			includeVariants: this.includeVariants,
			culture: culture,
		};

		const result = await this.api.followUpChlService.csvExport(
			this.lastDataState,
			csvOptions
		);

		this.setLoading(false);

		return result.data;
	};
}

export default ListStore;

import { TFunction } from "i18next";
import { SummaryGridProps } from "../../../../../components/SummaryDraggableCard/SummaryDraggableCard";
import {
	getEstimatedOfMonth,
	getHeaders,
	getSumryGridProps,
	summaryModelBuilder,
	SummaryModelProps,
} from "./SummaryModel";

export interface SummaryTruckModelProps extends SummaryModelProps {
	line: string;
}

export function summaryTruckModelBuilder(data: any[]) {
	if (!data) return [];

	return data.map(
		(item) =>
			({
				...summaryModelBuilder(item),
				line: item.line,
			}) as SummaryTruckModelProps
	);
}

export function getSummaryDraggableData(data: SummaryTruckModelProps[]) {
	let dataTransformed = [] as SummaryGridProps[];
	let estimatedMonth = 0;

	if (!data) return { dataTransformed, estimatedMonth };

	let totalFirmSum = 0;
	let totalProbableSum = 0;
	let totalNoneSum = 0;
	data.forEach((data) => {
		dataTransformed = dataTransformed.concat([
			{
				value: data.line,
			},
			...getSumryGridProps(data),
		]);

		totalFirmSum += data.firm;
		totalProbableSum += data.probable;
		totalNoneSum += data.none;
	});

	const totalSummary: SummaryModelProps = {
		firm: totalFirmSum,
		probable: totalProbableSum,
		none: totalNoneSum,
	};

	dataTransformed = dataTransformed.concat([
		{ value: "Total" },
		...getSumryGridProps(totalSummary),
	]);
	estimatedMonth = getEstimatedOfMonth(totalFirmSum, totalProbableSum);

	return { dataTransformed, estimatedMonth };
}

export function getHeadersTruck(t: TFunction<"order", undefined>) {
	return [{ value: t("line") }, ...getHeaders(t)];
}

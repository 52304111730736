import {
	AVAILABILITY,
	BUS_BRAZIL,
	BUS_FREE_UNIT_VCI,
	BUS_FREE_UNIT_VCI_VDB,
	BUS_MEAC,
	BUS_MKT_COMPANY,
	BUS_PI,
	BUS_TOTAL,
	EUROPE_FACTORY_ORDERBOOK,
	EXTERNAL_BRAZIL,
	FD_MKT_COMPANY,
	FD_MKT_COMPANY_BUS,
	FD_MKT_COMPANY_EUROPE,
	INTERNAL_VDB,
	MKT_COMPANY,
	PRIVATE_IMPORTERS,
	TOTAL,
	VCI,
} from "../constants/Menus/OrderMenuUtils";
import { CsvRequestOrigin } from "../pages/DownloadCenter/List/enums/CsvRequestOrigin";
import { RoleType } from "../pages/DownloadCenter/List/enums/RoleType";

export function downloadCsv(content: any, name: string) {
	const link = document.createElement("a");

	link.href = window.URL.createObjectURL(new Blob([content.data]));

	link.setAttribute("download", name);

	document.body.appendChild(link);

	link.click();
}

export function getRoleText(role: RoleType): string {
	switch (role) {
		case RoleType.GeneralSalesSupport:
			return "card.role.general-sales-support";
		case RoleType.ArgentinaSalesSupport:
			return "card.role.argentina-sales-support";
		case RoleType.Chl_Market_Company:
		case RoleType.ChileSalesSupport:
			return "card.role.chile-sales-support";
		case RoleType.Col_Market_Company:
		case RoleType.Mex_Sales_Support:
		case RoleType.Meac_Sales_Support:
		case RoleType.Arg_Market_Company:
		case RoleType.Per_Market_Company:
		case RoleType.Pi_Sales_Support:
			return "card.role.market-company";
		case RoleType.ChileGeneralSalesSupport:
			return "card.role.general-chile-sales";
		case RoleType.Arg_Dealer_Agecer:
		case RoleType.Arg_Dealer_Caminoa:
		case RoleType.Arg_Dealer_Gerencia:
		case RoleType.Arg_Dealer_Gloker:
		case RoleType.Arg_Dealer_Gotland:
		case RoleType.Arg_Dealer_Latinacam:
		case RoleType.Arg_Dealer_Libertadores:
		case RoleType.Arg_Dealer_Rutasur:
		case RoleType.Arg_Dealer_Scevola:
		case RoleType.Arg_Dealer_Sudamericana:
		case RoleType.Arg_Dealer_Sueca:
			return "card.role.argentina-dealer";
		case RoleType.Bra_Dealer_Asp:
		case RoleType.Bra_Dealer_Aut:
		case RoleType.Bra_Dealer_Dic:
		case RoleType.Bra_Dealer_Dip:
		case RoleType.Bra_Dealer_Got:
		case RoleType.Bra_Dealer_Lap:
		case RoleType.Bra_Dealer_Luv:
		case RoleType.Bra_Dealer_Nor:
		case RoleType.Bra_Dealer_Riv:
		case RoleType.Bra_Dealer_Sue:
		case RoleType.Bra_Dealer_Tne:
		case RoleType.Bra_Dealer_Tno:
		case RoleType.Bra_Dealer_Tne_Tno:
		case RoleType.Bra_Dealer_Trv:
			return "card.role.brazil-dealer";
		case RoleType.None:
			return "card.role.none";
	}
}

export function getMenuReportName(origin: CsvRequestOrigin) {
	switch (origin) {
		case CsvRequestOrigin.ClosedPeriodArgTrucks:
			return "card.menu.closed-period-arg-trucks";
		case CsvRequestOrigin.ClosedPeriodArgBuses:
			return "card.menu.closed-period-arg-buses";
		case CsvRequestOrigin.FollowUpArgTrucks:
			return "card.menu.follow-up-arg-trucks";
		case CsvRequestOrigin.FollowUpArgBuses:
			return "card.menu.follow-up-arg-buses";
		case CsvRequestOrigin.FollowUpChlTrucks:
			return "card.menu.follow-up-chl-trucks";
		case CsvRequestOrigin.OrdersTrucks:
			return "card.menu.orders-trucks";
		case CsvRequestOrigin.OrdersBus:
			return "card.menu.orders-bus";
		case CsvRequestOrigin.Europe:
			return "card.menu.europe";
	}
}

export function getSubMenuName(menuKey: string) {
	switch (menuKey) {
		case FD_MKT_COMPANY_EUROPE:
		case FD_MKT_COMPANY_BUS:
		case FD_MKT_COMPANY:
			return "card.menu-key-name.factory-delivery";
		case BUS_PI:
		case PRIVATE_IMPORTERS:
			return "card.menu-key-name.private-importers";
		case BUS_MKT_COMPANY:
		case MKT_COMPANY:
			return "card.menu-key-name.market-company";
		case BUS_MEAC:
			return "card.menu-key-name.meac";
		case BUS_FREE_UNIT_VCI_VDB:
			return "card.menu-key-name.free-unit-vci-vdb";
		case BUS_FREE_UNIT_VCI:
		case VCI:
			return "card.menu-key-name.free-unit-vci";
		case BUS_TOTAL:
		case TOTAL:
		case EUROPE_FACTORY_ORDERBOOK:
			return "card.menu-key-name.total";
		case AVAILABILITY:
			return "card.menu-key-name.availability";
		case EXTERNAL_BRAZIL:
		case BUS_BRAZIL:
			return "card.menu-key-name.external-brazil";
		case INTERNAL_VDB:
			return "card.menu-key-name.internal-vdb";
		default:
			return "";
	}
}

import { Grid, Link } from "@mui/material";
import {
	Grid as KendoGrid,
	GridCellProps,
	GridColumn,
	GridFooterCellProps,
} from "@progress/kendo-react-grid";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { DealerQuotaProposalProps } from "../interfaces/DealerQuotaProposalProps";
import { useExportReadStore } from "../stores/ExportReadStore";
import DateTimeString from "../../../../utils/DateTimeToString";
import { CARD_SIZE } from "../constants/cardSize";

const ExportReadGrid: React.FC = () => {
	const { t } = useTranslation("quota");
	const {
		dealerQuotaProposal,
		dealerQuotaProposalTotal,
		hasFilterApplied,
		getQuotaProposalOrdersCsvByRegion,
	} = useExportReadStore();

	const MonthCell = (props: GridCellProps) => (
		<td>
			<span>
				{DateTimeString(props.dataItem[props.field ?? ""], "MMM/yyyy", "TOTAL")}
			</span>
		</td>
	);

	const DateCell = (props: GridCellProps) => (
		<td>
			<span>
				{DateTimeString(props.dataItem[props.field ?? ""], "DD/MM/YY", "-")}
			</span>
		</td>
	);

	const TotalCell = (props: GridFooterCellProps) => {
		let total = "";

		if (props.field === "period") total = "TOTAL";
		else
			total =
				dealerQuotaProposalTotal[
					props.field as keyof DealerQuotaProposalProps
				]?.toString() ?? "";

		return (
			<td>
				<span>{total}</span>
			</td>
		);
	};

	const getCustomCell = (props: any) => {
		if (props.dataItem.total === 0)
			return (
				<td>
					<span>{props.dataItem.total}</span>
				</td>
			);

		return (
			<td>
				<Link
					sx={{ fontSize: 13 }}
					className="link"
					component="button"
					onClick={() => {
						getQuotaProposalOrdersCsvByRegion(props.dataItem.period, t);
					}}
				>
					<span>{props.dataItem.total}</span>
				</Link>
			</td>
		);
	};

	return (
		<Grid item sx={{ width: CARD_SIZE }}>
			{hasFilterApplied() && (
				<KendoGrid
					data={dealerQuotaProposal}
					scrollable="none"
					className="k-grid-sm"
				>
					<GridColumn
						field="period"
						title={t("gridColumns.month")}
						cell={MonthCell}
						footerCell={TotalCell}
					/>
					<GridColumn
						field="proposal"
						title={t("gridColumns.proposal")}
						footerCell={TotalCell}
					/>
					<GridColumn
						field="orderBook"
						title={t("gridColumns.orderBook")}
						footerCell={TotalCell}
					/>
					<GridColumn
						field="quotaRegion"
						title={t("gridColumns.quotaRegion")}
						footerCell={TotalCell}
					/>
					<GridColumn
						field="total"
						title={t("gridColumns.total")}
						footerCell={TotalCell}
						cell={getCustomCell}
					/>
					<GridColumn
						field="gap"
						title={t("gridColumns.gap")}
						footerCell={TotalCell}
					/>
					<GridColumn
						field="firmPeriod"
						title={t("gridColumns.firmPeriod")}
						cell={DateCell}
					/>
				</KendoGrid>
			)}
		</Grid>
	);
};

export default observer(ExportReadGrid);

import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import SaveBar from "../../../../components/SaveBar/SaveBar";
import NoContentResult from "../../../../components/msal/NoContentResult";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import GeneralAccordion from "./Accordions/GeneralAccordion";
import DetailsTimeline from "./Utils/TimeLine";
import CommercialAccordion from "./Accordions/CommercialAccordion";
import VehicleAccordion from "./Accordions/VehicleAccordion";
import AvailabilityInvoicingDeliveryAccordion from "./Accordions/AvailabilityInvoicingDeliveryAccordion";
import { getBreadcrumbs } from "../utils/BreadcrumbsUtils";
import { useFollowUpArgStore } from "../stores/FollowUpArgStore";
import { TruckReadOnlyProps } from "../interfaces/TruckReadOnlyDetails";
import {
	fieldIsEditable,
	getFieldsAvailableForEditionByUser,
	getUserUpdatePermission,
} from "../utils/EditableFieldsUtils";
import { ownerGroupsOfFollowUpArg } from "../../../../constants/AuthGroups/followUpArgPage";

const FollowUpTruckDetailIndex: React.FC = () => {
	const { t } = useTranslation(["menu", "general"]);
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = React.useState(true);
	const { graphData, isAuthorizedGroup } = useAuthStore();

	const userIsOwner = isAuthorizedGroup(ownerGroupsOfFollowUpArg);
	const userUpdatePermission = getUserUpdatePermission(isAuthorizedGroup);
	const fieldsAvailableForEdition =
		getFieldsAvailableForEditionByUser(userUpdatePermission);
	const userCanEditDealer = fieldIsEditable(
		"dealerId",
		fieldsAvailableForEdition
	);
	const userCanEditVehicleLocation = fieldIsEditable(
		"vehicleLocationId",
		fieldsAvailableForEdition
	);
	const userCanEditShippingCompany = fieldIsEditable(
		"shippingCompany",
		fieldsAvailableForEdition
	);
	const userCanEditPaymentForm = fieldIsEditable(
		"paymentForm",
		fieldsAvailableForEdition
	);
	const userCanEditPaquete = fieldIsEditable(
		"paqueteId",
		fieldsAvailableForEdition
	);

	const {
		readOnlyDetails,
		editableDetails,
		loadAllDetails,
		loadVariants,
		update,
		setFieldByKeyOnChange,
		setOrderMCDeliveryFieldByKeyOnChange,
		setOrderMCManagementFieldByKeyOnChange,
		dealersOptions,
		shippingCompaniesOptions,
		vehicleLocationOptions,
		paymentTermsOptions,
		hasChanges,
		paqueteOptions,
	} = useFollowUpArgStore();

	const { i18n } = useTranslation();
	const language = Boolean(i18n.language) ? i18n.language : "en-US";

	const fetchDetails = React.useCallback(async () => {
		setLoading(true);
		const orderId = id ?? "";

		await loadAllDetails(
			orderId,
			graphData?.mail ?? "",
			"Truck",
			userCanEditDealer,
			userCanEditVehicleLocation,
			userCanEditShippingCompany,
			userCanEditPaymentForm,
			language
		).finally(() => setLoading(false));
	}, [
		id,
		loadAllDetails,
		graphData?.mail,
		userCanEditDealer,
		userCanEditVehicleLocation,
		userCanEditShippingCompany,
		userCanEditPaymentForm,
		language,
	]);

	React.useEffect(() => {
		fetchDetails();
	}, [fetchDetails]);

	const handleSubmit = async () => {
		setLoading(true);
		await update(userUpdatePermission)
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err.message))
			.finally(() => fetchDetails());
	};

	const breadcrumbs = getBreadcrumbs(t);

	return (
		<>
			<LoadingPage loading={loading} />
			{!loading && readOnlyDetails.id && (
				<>
					<TitleBox breadcrumbs={breadcrumbs} />
					<Box p={1}>
						<Grid container spacing={2}>
							<Grid item xs mb={20}>
								<Grid container spacing={2}>
									<GeneralAccordion
										lastUpdateDetails={{ ...readOnlyDetails }}
										generalDetails={{
											readOnlyFields: {
												...(readOnlyDetails as TruckReadOnlyProps),
											},
										}}
									/>
									<CommercialAccordion
										customerDetails={{
											fieldsAvailableForEdition,
											readOnlyFields: { ...readOnlyDetails },
											editableFields: {
												...editableDetails,
												...editableDetails.orderMCManagement,
											},
											setFieldByKeyOnChange,
											setOrderMCManagementFieldByKeyOnChange,
											dealersOptions,
										}}
										mktCompanyCommercial={{
											fieldsAvailableForEdition,
											editableFields: { ...editableDetails.orderMCManagement },
											setOrderMCManagementFieldByKeyOnChange,
											vehicleLocationOptions,
											paymentTermsOptions,
										}}
										mktCompanyAdditionalComments={{
											fieldsAvailableForEdition,
											editableFields: { ...editableDetails.orderMCManagement },
											setOrderMCManagementFieldByKeyOnChange,
										}}
										mktCompanyDeliverySection={{
											userIsOwner,
											fieldsAvailableForEdition,
											editableFields: {
												...editableDetails.orderMCDelivery,
												...editableDetails.orderMCManagement,
											},
											setOrderMCDeliveryFieldByKeyOnChange,
											setOrderMCManagementFieldByKeyOnChange,
										}}
										generalComment={{
											fieldsAvailableForEdition,
											generalComment: editableDetails.generalComment,
											setFieldByKeyOnChange,
										}}
										paymentTermsOptions={paymentTermsOptions}
									/>
									<VehicleAccordion
										modelDetails={{
											readOnlyFields: {
												...(readOnlyDetails as TruckReadOnlyProps),
											},
											editableFields: {
												...editableDetails.orderMCManagement,
											},
											setOrderMCManagementFieldByKeyOnChange,
											userCanEditPaquete,
											paqueteOptions,
										}}
										vehicleIdentificationDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										variantsDetails={{
											readOnlyFields: { ...readOnlyDetails },
											loadVariants: loadVariants,
										}}
										engineeringCommentsDetails={{
											fieldsAvailableForEdition,
											engineeringComments:
												editableDetails.orderMCManagement.engineeringComments,
											setOrderMCManagementFieldByKeyOnChange,
										}}
									/>
									<AvailabilityInvoicingDeliveryAccordion
										forecastBillingDetails={{
											fieldsAvailableForEdition,
											editableFields: {
												...editableDetails.orderMCManagement,
											},
											setOrderMCManagementFieldByKeyOnChange,
											shippingCompaniesOptions,
										}}
									/>
								</Grid>
								<SaveBar
									onResetClick={() => fetchDetails()}
									resetLabel={t("undoChanges", { ns: "general" })}
									onSaveClick={() => handleSubmit()}
									saveLabel={t("saveChanges", { ns: "general" })}
									hasFormChanges={hasChanges}
								/>
							</Grid>
							<DetailsTimeline />
						</Grid>
					</Box>
					<ToastContainer />
				</>
			)}
			{!loading && !readOnlyDetails.id && <NoContentResult />}
		</>
	);
};

export default observer(FollowUpTruckDetailIndex);

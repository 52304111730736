import { Box, Button, Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { Search } from "@mui/icons-material";
import LineFilter from "../../../../components/Filters/LineFilter";
import { useExportReadStore } from "../stores/ExportReadStore";
import MonthFilter from "../../../../components/Filters/MonthFilter";
import DealerExportFilter from "../../../../components/Filters/DealerExportFilter";

const ExportReadFilters: React.FC = () => {
	const { setFilterByKeyOnChange, setFilter, filter, filterIsValid } =
		useExportReadStore();

	return (
		<Box sx={{ display: "flex" }}>
			<Grid container sx={{ display: "flex", height: "90px" }} spacing={3}>
				<Grid item>
					<LineFilter
						set={(e) => setFilterByKeyOnChange("line", e.value)}
						value={filter.line}
					/>
				</Grid>
				<Grid item>
					<DealerExportFilter
						set={(e) =>
							setFilterByKeyOnChange("dealerRegion", e.value, e.isValid)
						}
						value={filter.dealerRegion}
					/>
				</Grid>
				<Grid item>
					<MonthFilter
						set={(e) => setFilterByKeyOnChange("date", e.value, e.isValid)}
						value={filter.date}
					/>
				</Grid>
				<Grid item sx={{ alignSelf: "center" }}>
					<Button
						variant="contained"
						onClick={setFilter}
						size="small"
						disabled={!filterIsValid}
					>
						<Search fontSize="small" />
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export default observer(ExportReadFilters);

import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { ITrucksPreFilters } from "./interfaces/ITrucksPreFilters";
import PreFilter from "../../../../../components/PreFilter/PreFilter";
import { useTranslation } from "react-i18next";
import { CTADMVEN_FOLLOWUP_CHL } from "../../../../../constants/routes";
import { CHASSI_AO } from "../../../../../constants/chassiAoOmFoConst";
import LineSequenceDatePreFilter from "../../../../../components/PreFilter/containers/LineSequenceDatePreFilter";
import MonthAvailabilityPreFilter from "../../../../../components/PreFilter/containers/MonthAvailabilityPreFilter";
import DeliveryProposalPreFilter from "../../../../../components/PreFilter/containers/DeliveryProposalPreFilter";
import ChassiAOPreFilter from "../../../../../components/PreFilter/containers/ChassiAOPreFilter";
import LinePreFilter from "../../../../../components/PreFilter/containers/LinePreFilter";
import ZonePreFilter from "../../../../../components/PreFilter/containers/ZonePreFilter";
import AsWeekPreFilter from "../../../../../components/PreFilter/containers/AsWeekPreFilter";
import CommercialModelPreFilter from "../../../../../components/PreFilter/containers/CommercialModelPreFilter";
import CustomerSASPreFilter from "../../../../../components/PreFilter/containers/CustomerSASPreFilter";
import CustomerOMPreFilter from "../../../../../components/PreFilter/containers/CustomerOMPreFilter";
import SalesmanPreFilter from "../../../../../components/PreFilter/containers/SalesmanPreFilter";
import QuotationCustomerPreFilter from "../../../../../components/PreFilter/containers/QuotationCustomerPreFilter";
import QuotationSalesmanPreFilter from "../../../../../components/PreFilter/containers/QuotationSalesmanPreFilter";

const TrucksPreFilters: React.FC<ITrucksPreFilters> = ({
	monthAvailability,
}) => {
	const { menu } = useStores().listStore;

	const {
		preFilterChassiAOStore,
		preFilterAsWeekStore,
		preFilterCommercialModelStore,
		preFilterCustomerOMStore,
		preFilterCustomerSASStore,
		preFilterQuotationCustomerStore,
		preFilterQuotationSalesmanStore,
		preFilterSalesmanStore,
		preFilterLineSequenceDateStore,
		preFilterMonthAvailabilityStore,
		preFilterDeliveryProposalStore,
		preFilterLineStore,
		preFilterZoneStore,
	} = useStores();

	const { getOptions: getOptionsLine } = preFilterLineStore;
	const { getOptions: getOptionsZone } = preFilterZoneStore;
	const {
		applyFilters,
		hasPreFiltersApplied,
		hasFormatError,
		filters,
		removeFilter,
		cleanSpecificPreFilters,
		onUndo,
	} = useStores().filterStore;

	const { t } = useTranslation(["order", "general"]);

	React.useEffect(() => {
		getOptionsLine();
	}, [getOptionsLine, menu]);

	const initialFetch = React.useCallback(() => {
		getOptionsZone(t);
	}, [getOptionsZone, t]);

	React.useEffect(() => {
		initialFetch();
	}, [initialFetch]);

	React.useEffect(() => {
		return () => {
			cleanSpecificPreFilters();
		};
	}, []);

	return (
		<PreFilter
			id="pre-filter-edition"
			title={t("preFilters.quickFilter", { ns: "order" })}
			onUndo={onUndo}
			onSubmit={applyFilters}
			hasPreFiltersApplied={hasPreFiltersApplied}
			hasFormatError={hasFormatError}
			filters={filters}
			chassiType={CHASSI_AO}
			removeFilter={removeFilter}
			page={CTADMVEN_FOLLOWUP_CHL}
		>
			<ChassiAOPreFilter
				vehicleType={"Truck"}
				type={CHASSI_AO}
				preFilterStore={preFilterChassiAOStore}
			/>
			<DeliveryProposalPreFilter
				preFilterStore={preFilterDeliveryProposalStore}
			/>
			<MonthAvailabilityPreFilter
				{...monthAvailability}
				preFilterStore={preFilterMonthAvailabilityStore}
			/>
			<LineSequenceDatePreFilter
				preFilterStore={preFilterLineSequenceDateStore}
			/>
			<LinePreFilter preFilterStore={preFilterLineStore} xs={4} />
			<ZonePreFilter preFilterStore={preFilterZoneStore} />
			<AsWeekPreFilter preFilterStore={preFilterAsWeekStore} />
			<CommercialModelPreFilter
				preFilterStore={preFilterCommercialModelStore}
				sm={4}
				md={4}
				xl={1}
			/>
			<CustomerSASPreFilter
				preFilterStore={preFilterCustomerSASStore}
				lg={4}
				xl={4}
			/>
			<CustomerOMPreFilter preFilterStore={preFilterCustomerOMStore} xl={4} />
			<SalesmanPreFilter preFilterStore={preFilterSalesmanStore} />
			<QuotationCustomerPreFilter
				preFilterStore={preFilterQuotationCustomerStore}
			/>
			<QuotationSalesmanPreFilter
				preFilterStore={preFilterQuotationSalesmanStore}
			/>
		</PreFilter>
	);
};

export default observer(TrucksPreFilters);

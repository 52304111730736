import { action, makeObservable, observable } from "mobx";
import React from "react";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { FilterProps } from "../interfaces/FilterProps";
import { GridDataTableProps } from "../interfaces/GridDataTableProps";
import { filterModel } from "../models/FilterModel";
import { CalendarWeekProps } from "../interfaces/CalendarWeekProps";
import { ProductionWeekCalendarProps } from "../interfaces/ProductionWeekCalendarProps";
import { productionWeekCalendarModel } from "../models/ProductionWeekCalendarModel";
import { CalendarDatesProps } from "../interfaces/CalendarDatesProps";
import { getStringToDateFormat } from "../../../../utils/DateUtils";
import { LineType } from "../../../../utils/GenericTypes";
import { LogEventProps } from "../../../../utils/LogEventProps";

class ProductionWeekStore {
	@observable filter: FilterProps = filterModel;
	@observable filterIsValid: boolean = true;
	@observable line: LineType = "F";
	@observable date: Date = new Date();
	@observable timePeriod: number = 3;
	@observable lastUpdateDate: Date = new Date();
	@observable initialGridDataTable: Array<GridDataTableProps> = [];
	@observable gridDataTable: Array<GridDataTableProps> = [];
	@observable isLoading: boolean = false;
	@observable logEvent: Partial<LogEventProps> = {};

	initialData: ProductionWeekCalendarProps = productionWeekCalendarModel;
	data: ProductionWeekCalendarProps = productionWeekCalendarModel;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();

		makeObservable(this);
	}

	@action resetStore = () => {
		this.initialGridDataTable = [];
		this.gridDataTable = [];
		this.filter = filterModel;
		this.line = "F";
		this.date = new Date();
		this.timePeriod = 3;
		this.lastUpdateDate = new Date();
		this.isLoading = false;
		this.logEvent = {};
	};

	@action resetDataAndGridData = () => {
		this.setDataAndInital(this.initialData);
		this.setGridDataTableAndInital();
	};

	@action setFilterByKeyOnChange = <K extends keyof FilterProps>(
		fieldKey: K,
		newValue: FilterProps[K],
		isValid: boolean
	) => {
		const _filter: FilterProps = Object.assign({}, this.filter);
		_filter[fieldKey] = newValue;
		this.filter = _filter;
		this.filterIsValid = isValid;
	};

	@action setFilter = () => {
		this.line = this.filter.line;
		this.date = this.filter.date;
		this.timePeriod = this.filter.timePeriod;

		this.getProductionWeekCalendar();
	};

	@action setGridDataTableAndInital = () => {
		this.gridDataTable = this.initialData.calendarWeeks.map(
			(x: CalendarWeekProps) => this.getGridDataTableProps(x)
		);
		this.initialGridDataTable = this.gridDataTable;
	};

	@action setDataAndInital = (data: ProductionWeekCalendarProps) => {
		this.data = data;
		this.initialData = data;
		this.logEvent = data.logEvent;
	};

	@action setAvailableDay = (event: CalendarDatesProps[]) => {
		if (event[0].readonly) return;
		const newGridStateData = this.gridDataTable.map((gridDataTable) => ({
			...gridDataTable,
			dayGridProps: gridDataTable.dayGridProps.map((calendarDate) => ({
				...calendarDate,
				available:
					calendarDate.date === event[0].date
						? !calendarDate.available
						: calendarDate.available,
			})),
		}));

		this.setGridDataTable(newGridStateData);

		const calendarWeekIndex = this.data.calendarWeeks.findIndex((f) =>
			f.calendarDates.some((s) => s.date === event[0].date)
		);
		const calendarDateIndex = this.data.calendarWeeks[
			calendarWeekIndex
		].calendarDates.findIndex((f) => f.date === event[0].date);

		let newCalendarDateObj =
			this.data.calendarWeeks[calendarWeekIndex].calendarDates[
				calendarDateIndex
			];
		newCalendarDateObj = {
			...newCalendarDateObj,
			available: !newCalendarDateObj.available,
		};
		this.data.calendarWeeks[calendarWeekIndex].calendarDates[
			calendarDateIndex
		] = newCalendarDateObj;
	};

	@action setGridDataTable = (data: Array<GridDataTableProps>) =>
		(this.gridDataTable = data);

	@action getProductionWeekCalendar = async () => {
		this.lastUpdateDate = new Date();
		this.isLoading = true;

		await this.api.parametersService
			.getProductionWeekCalendar(
				this.line,
				this.date.toISOString(),
				this.timePeriod
			)
			.then((x) => {
				this.setDataAndInital(x.data);
			})
			.finally(() => (this.isLoading = false));

		if (this.initialData) {
			this.setGridDataTableAndInital();
		}
	};

	@action updateProductionCalendar = async (userEmail: string) => {
		this.lastUpdateDate = new Date();
		this.isLoading = true;

		this.data = {
			...this.data,
			userEmail,
		};

		await this.api.parametersService
			.updateProductionWeekCalendar(this.data)
			.then(async () => {
				await this.getProductionWeekCalendar();
			})
			.finally(() => (this.isLoading = false));
	};

	@action private getGridDataTableProps = (
		calendarWeekProps: CalendarWeekProps
	): GridDataTableProps => {
		let weekProps: GridDataTableProps = {
			month: getStringToDateFormat(calendarWeekProps.initialDate),
			week: calendarWeekProps.yearWeek,
			dayGridProps: calendarWeekProps.calendarDates,
		};

		return weekProps;
	};
}

const ProductionWeekStoreContext = React.createContext(
	new ProductionWeekStore()
);

export const useProductionWeekStore = () =>
	React.useContext(ProductionWeekStoreContext);

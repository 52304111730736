import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import { EuropeMenus } from "../../../../../../constants/Menus/OrderMenuUtils";
import {
	getFilterObjectArrayOfOrderKeyBySize,
	getMultiValueFilterSplitter,
} from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterChassiAOStore {
	@observable rootStore;
	@observable preFilter: string = "";
	@observable errorMessage: string = "";
	@observable hasFormatError: boolean = false;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = "";
		this.errorMessage = "";
		this.setHasFormatError(false);
	};

	@action clean = () => this.reset();

	@action getFilterBySize = (value: string) => {
		const prefix = this.isEuropeMenu() ? "" : "order.";
		const assemblyName = this.isEuropeMenu() ? "omNumber" : "assemblyOrder";

		return getFilterObjectArrayOfOrderKeyBySize(
			"chassi",
			assemblyName,
			"factoryOrder",
			prefix,
			value
		);
	};

	@action setErrorMessage = (value: string) => (this.errorMessage = value);

	@action set = (value: string) => {
		let preChassiOrAoSplited: string[] = [];
		this.preFilter = value;

		if (!this.preFilter) {
			this.setHasFormatError(false);
			return;
		}

		if (this.preFilter.includes(" ")) {
			preChassiOrAoSplited = this.preFilter.split(" ");
		} else if (this.preFilter.includes(",")) {
			preChassiOrAoSplited = this.preFilter.split(",");
		} else {
			preChassiOrAoSplited = [this.preFilter];
		}

		let hasError = false;
		preChassiOrAoSplited.forEach((chassiOrAo) => {
			if (this.isEuropeMenu() && chassiOrAo.length === 7) {
				hasError = true;
				return;
			}

			let filterObj = this.getFilterBySize(chassiOrAo);

			if (!filterObj || !filterObj.length || !filterObj[0].field)
				hasError = hasError || true;
			else hasError = hasError || false;
		});

		this.setHasFormatError(hasError);
	};

	@action setHasFormatError = (value: boolean) => {
		this.hasFormatError = value;
		this.rootStore.filterStore.setHasFormatError(this.hasFormatError);
	};

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		andFilters = andFilters.concat(this.getFilterBySize(this.preFilter));

		return andFilters;
	}

	@action getMultiValueFilterDescriptor(
		orFiltersGroups: Array<Array<FilterDescriptor>>,
		splitBy: string
	) {
		if (this.preFilter === "") return;

		const splittedFilter = this.preFilter.split(splitBy);

		if (splittedFilter.every((x) => x.length === 0)) return;

		const filters = splittedFilter.map((x: string) => {
			return this.getFilterBySize(x).shift() as FilterDescriptor;
		});

		if (filters) orFiltersGroups.push(filters);
	}

	@action getFilterDescriptorBySize = (
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>
	) => {
		const splitter = getMultiValueFilterSplitter(this.preFilter);
		if (!splitter) andFilters = this.getFilterDescriptor(andFilters);
		else this.getMultiValueFilterDescriptor(orFiltersGroups, splitter);

		return andFilters;
	};

	isEuropeMenu = () =>
		EuropeMenus.some((x) => x === this.rootStore.listStore.menu);
}

export default PreFilterChassiAOStore;

import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../../components/AccordionCard/AccordionCard";
import { InvoiceDeliveryAccordionProps } from "../../../interfaces/Accordions/Europe/InvoiceDeliveryAccordion";
import ExportationInvoiceSection from "../../Sections/Europe/ExportationInvoiceSection";
import ExportationLocationSection from "../../Sections/Europe/ExportationLocationSection";
import InvoicingDeliveryMarketCompanySection from "../../Sections/Europe/InvoicingDeliveryMarketCompanySection";
import { useAuthStore } from "../../../../../../auth/store/AuthStore";
import { groupsAllowedToSeeInvoicingDeliveryMarketCompanySection } from "../../../../../../constants/AuthGroups/orderDetails";
import { FactoryDeliveryMenus } from "../../../../../../constants/Menus/OrderMenuUtils";

const InvoiceDeliveryAccordion: React.FC<InvoiceDeliveryAccordionProps> = (
	details
) => {
	const { t } = useTranslation("order");
	const { isAuthorizedGroup } = useAuthStore();

	const userCanSeeInvoicingDeliveryMarketCompanySection = isAuthorizedGroup(
		groupsAllowedToSeeInvoicingDeliveryMarketCompanySection
	);

	const menuAllowedToShowInvincingDeliveryMarketCompany =
		FactoryDeliveryMenus.some((x) => x === details.menuKey);

	return (
		<AccordionCard
			id="invoiceDeliveryAccordion"
			title={t("details.accordions.invoicingDelivery")}
		>
			<ExportationLocationSection {...details.exportationLocationProps} />
			<ExportationInvoiceSection {...details.exportationInvoiceProps} />
			{userCanSeeInvoicingDeliveryMarketCompanySection &&
				menuAllowedToShowInvincingDeliveryMarketCompany && (
					<InvoicingDeliveryMarketCompanySection
						{...details.invoicingDeliveryMarketCompanyProps}
					/>
				)}
		</AccordionCard>
	);
};

export default observer(InvoiceDeliveryAccordion);

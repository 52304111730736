import { Box, Button, Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { Search } from "@mui/icons-material";
import LineFilter from "../../../../../components/Filters/LineFilter";
import { useVolvoDealerReadStore } from "../../stores/VolvoDealerReadStore";
import DealerBrazilFilter from "../../../../../components/Filters/DealerBrazilFilter";
import MonthFilter from "../../../../../components/Filters/MonthFilter";
import { BRA_DEALER_TNE_TNO } from "../../../../../constants/AuthGroups/authGroups";
import { useAuthStore } from "../../../../../auth/store/AuthStore";

const VolvoDealerReadFilters: React.FC = () => {
	const {
		setFilterByKeyOnChange,
		setFilter,
		setIsTracbelUser,
		filter,
		filterIsValid,
	} = useVolvoDealerReadStore();

	const { isAuthorizedGroup } = useAuthStore();
	setIsTracbelUser(isAuthorizedGroup([BRA_DEALER_TNE_TNO]));

	return (
		<Box sx={{ display: "flex" }}>
			<Grid container sx={{ display: "flex", height: "90px" }} spacing={3}>
				<Grid item>
					<LineFilter
						set={(e) => setFilterByKeyOnChange("line", e.value)}
						value={filter.line}
					/>
				</Grid>
				<Grid item>
					<DealerBrazilFilter
						set={(e) =>
							setFilterByKeyOnChange("dealerRegion", e.value, e.isValid)
						}
						value={filter.dealerRegion}
					/>
				</Grid>
				<Grid item>
					<MonthFilter
						set={(e) => setFilterByKeyOnChange("date", e.value, e.isValid)}
						value={filter.date}
					/>
				</Grid>
				<Grid item sx={{ alignSelf: "center" }}>
					<Button
						variant="contained"
						onClick={setFilter}
						size="small"
						disabled={!filterIsValid}
					>
						<Search fontSize="small" />
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};

export default observer(VolvoDealerReadFilters);

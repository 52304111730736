import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import {
	getCurrencyEnumOptions,
	getPreFilterNullOptions,
} from "../../../../../utils/SelectOptions";
import { useTranslation } from "react-i18next";
import { ARG_DEALERS } from "../../../../../constants/AuthGroups/authGroups";
import { getFollowUpArgProbabilityOptions } from "../../../../../utils/enums/ArgDeliveryProbabilityEnum";
import PreFilter from "../../../../../components/PreFilter/PreFilter";
import {
	CHASSI_AO,
	CHASSI_FO,
} from "../../../../../constants/chassiAoOmFoConst";
import PrevisionBillingMonthPreFilter from "../../../../../components/PreFilter/containers/PrevisionBillingMonthPreFilter";
import GokDatePreFilter from "../../../../../components/PreFilter/containers/GokDatePreFilter";
import AsWeekDatePreFilter from "../../../../../components/PreFilter/containers/AsWeekDatePreFilter";
import CertificateDatePreFilter from "../../../../../components/PreFilter/containers/CertificateDatePreFilter";
import ShippingDatePreFilter from "../../../../../components/PreFilter/containers/ShippingDatePreFilter";
import { FOLLOWUP_MENU_ARG_TRUCK } from "../../../../../constants/Menus/FollowUpMenuUtils";
import ChassiAOPreFilter from "../../../../../components/PreFilter/containers/ChassiAOPreFilter";
import CustomerSASPreFilter from "../../../../../components/PreFilter/containers/CustomerSASPreFilter";
import DestinationPreFilter from "../../../../../components/PreFilter/containers/DestinationPreFilter";
import CurrencyPreFilter from "../../../../../components/PreFilter/containers/CurrencyPreFilter";
import PaymentFormPreFilter from "../../../../../components/PreFilter/containers/PaymentFormPreFilter";
import VehicleLocationPreFilter from "../../../../../components/PreFilter/containers/VehicleLocationPreFilter";
import BusinessControlVehiclePreFilter from "../../../../../components/PreFilter/containers/BusinessControlVehiclePreFilter";
import CustomerOMPreFilter from "../../../../../components/PreFilter/containers/CustomerOMPreFilter";
import DeliveryProbabilityPreFilter from "../../../../../components/PreFilter/containers/DeliveryProbabilityPreFilter";
import FirmBillingPreFilter from "../../../../../components/PreFilter/containers/FirmBillingPreFilter";
import FinancialDeliveryPreFilter from "../../../../../components/PreFilter/containers/FinancialDeliveryPreFilter";
import PhysicalDeliveryPreFilter from "../../../../../components/PreFilter/containers/PhysicalDeliveryPreFilter";
import FinalDeliveryPreFilter from "../../../../../components/PreFilter/containers/FinalDeliveryPreFilter";
import DealerPreFilter from "../../../../../components/PreFilter/containers/DealerPreFilter";
import PaquetePreFilter from "../../../../../components/PreFilter/containers/PaquetePreFilter";
import { GRID_MAX_SIZE } from "../../../../../constants/gridConst";

const PreFilters: React.FC = () => {
	const { t, i18n } = useTranslation("order");
	const language = i18n.language ? i18n.language : "en-US";

	const { setOptions: setOptionsDeliveryProbability } =
		useStores().preFilterDeliveryProbabilityStore;
	const {
		setPreFiltersCheckedOptions,
		cleanAllFilters,
		applyFilters,
		hasPreFiltersApplied,
		hasFormatError,
		filters,
		removeFilter,
		cleanSpecificPreFilters,
		onUndo,
	} = useStores().filterStore;

	const {
		preFilterPrevisionBillingMonthStore,
		preFilterAsWeekDateStore,
		preFilterCertificateDateStore,
		preFilterGokDateStore,
		preFilterShippingDateStore,
		preFilterDealerStore,
		preFilterPaymentFormStore,
		preFilterVehicleLocationStore,
		preFilterCurrencyStore,
		preFilterChassiAOStore,
		preFilterCustomerStoreSAS,
		preFilterDestinationStore,
		preFilterBusinessControlVehicleStore,
		preFilterCustomerStoreOM,
		preFilterDeliveryProbabilityStore,
		preFilterFirmBillingStore,
		preFilterFinancialDeliveryStore,
		preFilterPhysicalDeliveryStore,
		preFilterFinalDeliveryStore,
		preFilterPaqueteStore,
	} = useStores();

	const { getOptions: getOptionsDealer } = preFilterDealerStore;
	const { getOptions: getOptionsPaymentForm } = preFilterPaymentFormStore;
	const { getOptions: getOptionsVehicleLocation } =
		preFilterVehicleLocationStore;
	const { setOptions: setOptionsCurrency } = preFilterCurrencyStore;
	const { getOptions: getOptionsPaquete } = preFilterPaqueteStore;
	const { menu } = useStores().listStore;

	const { isAuthorizedGroup } = useAuthStore();
	const shouldShowDealerPreFilter = !isAuthorizedGroup(ARG_DEALERS);

	const fetchOptions = React.useCallback(() => {
		cleanAllFilters();
		setOptionsDeliveryProbability(getFollowUpArgProbabilityOptions(t));
		setPreFiltersCheckedOptions(getPreFilterNullOptions(t));
		setOptionsCurrency(getCurrencyEnumOptions(t));
		getOptionsPaymentForm(language);
		getOptionsVehicleLocation();
		getOptionsPaquete();
		if (shouldShowDealerPreFilter) {
			getOptionsDealer();
		}
	}, [
		cleanAllFilters,
		setOptionsDeliveryProbability,
		t,
		setPreFiltersCheckedOptions,
		setOptionsCurrency,
		getOptionsPaymentForm,
		language,
		getOptionsVehicleLocation,
		shouldShowDealerPreFilter,
		getOptionsDealer,
		getOptionsPaquete,
	]);

	React.useEffect(() => {
		fetchOptions();
	}, [fetchOptions]);

	React.useEffect(() => {
		return () => {
			cleanSpecificPreFilters();
		};
	}, []);

	const isTruck = menu === FOLLOWUP_MENU_ARG_TRUCK;
	const vehicleType = isTruck ? "Truck" : "Bus";
	const chassiFilter = isTruck ? CHASSI_AO : CHASSI_FO;

	return (
		<PreFilter
			id="pre-filter-edition"
			title={t("preFilters.quickFilter")}
			onUndo={onUndo}
			onSubmit={applyFilters}
			hasPreFiltersApplied={hasPreFiltersApplied}
			hasFormatError={hasFormatError}
			filters={filters}
			chassiType={CHASSI_AO}
			removeFilter={removeFilter}
		>
			<ChassiAOPreFilter
				vehicleType={vehicleType}
				type={chassiFilter}
				preFilterStore={preFilterChassiAOStore}
			/>
			<DeliveryProbabilityPreFilter
				preFilterStore={preFilterDeliveryProbabilityStore}
			/>
			<PrevisionBillingMonthPreFilter
				preFilterStore={preFilterPrevisionBillingMonthStore}
			/>
			<FirmBillingPreFilter preFilterStore={preFilterFirmBillingStore} />
			<FinancialDeliveryPreFilter
				preFilterStore={preFilterFinancialDeliveryStore}
			/>
			<PhysicalDeliveryPreFilter
				preFilterStore={preFilterPhysicalDeliveryStore}
			/>
			<FinalDeliveryPreFilter preFilterStore={preFilterFinalDeliveryStore} />
			<BusinessControlVehiclePreFilter
				preFilterStore={preFilterBusinessControlVehicleStore}
			/>
			<CustomerSASPreFilter
				preFilterStore={preFilterCustomerStoreSAS}
				sm={5}
				md={4}
				lg={4}
				xl={3}
			/>
			<CustomerOMPreFilter
				preFilterStore={preFilterCustomerStoreOM}
				sm={5}
				md={4}
				lg={4}
				xl={3}
			/>
			<DestinationPreFilter preFilterStore={preFilterDestinationStore} />
			<CurrencyPreFilter preFilterStore={preFilterCurrencyStore} />
			<PaymentFormPreFilter preFilterStore={preFilterPaymentFormStore} />
			<VehicleLocationPreFilter
				preFilterStore={preFilterVehicleLocationStore}
			/>
			<GokDatePreFilter preFilterStore={preFilterGokDateStore} />
			<AsWeekDatePreFilter preFilterStore={preFilterAsWeekDateStore} />
			<ShippingDatePreFilter preFilterStore={preFilterShippingDateStore} />
			<CertificateDatePreFilter
				preFilterStore={preFilterCertificateDateStore}
			/>
			{shouldShowDealerPreFilter && (
				<DealerPreFilter preFilterStore={preFilterDealerStore} />
			)}
			{isTruck && <PaquetePreFilter preFilterStore={preFilterPaqueteStore} />}
		</PreFilter>
	);
};

export default observer(PreFilters);

import { FilterDescriptor } from "@progress/kendo-data-query";
import RootStore from "../../../stores/RootStore";
import { action, configure, makeObservable, observable } from "mobx";
import {
	OrderBookMenuType,
	TOTAL,
} from "../../../../../../constants/Menus/OrderMenuUtils";
import {
	getFilterObject,
	getMultiValueFilterSplitter,
} from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterChassiAOStore {
	@observable menu: OrderBookMenuType = TOTAL;
	@observable rootStore;
	@observable preFilter: string = "";
	@observable errorMessage: string = "";
	@observable hasFormatError: boolean = false;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.reset();
		makeObservable(this);
	}

	@action reset = () => {
		this.preFilter = "";
		this.errorMessage = "";
		this.setHasFormatError(false);
	};

	@action setMenu = (value: OrderBookMenuType) => (this.menu = value);

	@action clean = () => this.reset();

	@action getFilterBySize = (value: string) => {
		value = value.trim();

		if (value === "") return [];

		var operation = "eq";
		var field = "";
		var chassi = "chassi";
		var aOOMNumber = "aOOMNumber";

		switch (value.length) {
			case 6:
				field = chassi;
				break;

			case 7:
				field = chassi;
				value = value.substring(1);
				break;

			case 8:
				field = aOOMNumber;
				break;

			case 11:
				field = aOOMNumber;
				value = value.substring(2).replace(" ", "");
				break;

			default:
				break;
		}

		return [getFilterObject(field, value, operation)];
	};

	@action getMultiValueFilterDescriptor(
		orFiltersGroups: Array<Array<FilterDescriptor>>,
		splitBy: string
	) {
		if (this.preFilter === "") return;

		const splittedFilter = this.preFilter.split(splitBy);

		if (splittedFilter.every((x) => x.length === 0)) return;

		const filters = splittedFilter.map((x: string) => {
			return this.getFilterBySize(x).shift() as FilterDescriptor;
		});

		if (filters) orFiltersGroups.push(filters);
	}

	@action setErrorMessage = (value: string) => (this.errorMessage = value);

	@action set = (value: string) => {
		if (value !== "") this.rootStore.filterStore.hasPreFiltersApplied = true;

		this.preFilter = value;
		let preChassiOrAoSplited: string[] = [];

		if (!this.preFilter) {
			this.setHasFormatError(false);
			return;
		}

		if (this.preFilter.includes(" ")) {
			preChassiOrAoSplited = this.preFilter.split(" ");
		} else if (this.preFilter.includes(",")) {
			preChassiOrAoSplited = this.preFilter.split(",");
		} else {
			preChassiOrAoSplited = [this.preFilter];
		}

		let hasError = false;
		preChassiOrAoSplited.forEach((chassiOrAo) => {
			let filterObj = this.getFilterBySize(chassiOrAo);

			if (!filterObj || !filterObj.length || !filterObj[0].field)
				hasError = hasError || true;
			else hasError = hasError || false;
		});

		this.setHasFormatError(hasError);
	};

	@action setHasFormatError = (value: boolean) => {
		this.hasFormatError = value;
		this.rootStore.filterStore.setHasFormatError(this.hasFormatError);
	};

	@action getFilterDescriptor(andFilters: Array<FilterDescriptor>) {
		andFilters = andFilters.concat(this.getFilterBySize(this.preFilter));

		return andFilters;
	}

	@action getFilterDescriptorBySize = (
		andFilters: Array<FilterDescriptor>,
		orFiltersGroups: Array<Array<FilterDescriptor>>
	) => {
		const splitter = getMultiValueFilterSplitter(this.preFilter);
		if (!splitter) andFilters = this.getFilterDescriptor(andFilters);
		else this.getMultiValueFilterDescriptor(orFiltersGroups, splitter);

		return andFilters;
	};
}

export default PreFilterChassiAOStore;

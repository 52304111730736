import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import NoContentResult from "../../../../components/msal/NoContentResult";
import GeneralAccordion from "./Accordions/Bus/GeneralAccordion";
import DetailsTimeline from "./Utils/TimeLine";
import CommercialAccordion from "./Accordions/CommercialAccordion";
import VehicleAccordion from "./Accordions/Bus/VehicleAccordion";
import AvailabilityInvoicingDeliveryAccordion from "./Accordions/AvailabilityInvoicingDeliveryAccordion";
import { getBreadcrumbs } from "../utils/BreadcrumbsUtils";
import { BusReadOnlyProps } from "../interfaces/BusReadOnlyDetails";
import { useClosedPeriodArgStore } from "../stores/ClosedPeriodArgStore";
import OpenOrderButtonAndModal from "./Utils/OpenOrderButtonAndModal";
import { groupsAllowedToUpdateForecastBillingClosedPeriodOrdersArg } from "../../../../constants/AuthGroups/closedPeriodArgPage";
import { useAuthStore } from "../../../../auth/store/AuthStore";
import ProductionAccordion from "./Accordions/ProductionAccordion";
import SaveBar from "../../../../components/SaveBar/SaveBar";
import { ARG_DEALERS } from "../../../../constants/AuthGroups/authGroups";

const ClosedPeriodBusDetailIndex: React.FC = () => {
	const { t } = useTranslation(["ctadmven", "menu", "general"]);
	const { id } = useParams<{ id: string }>();
	const [loading, setLoading] = React.useState(true);

	const {
		readOnlyDetails,
		loadAllDetails,
		loadVariantsBus,
		editableDetails,
		setOrderMCManagementFieldByKeyOnChange,
		hasChanges,
		update,
	} = useClosedPeriodArgStore();

	const handleSubmit = async () => {
		setLoading(true);
		await update()
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err.message))
			.finally(() => fetchDetails());
	};

	const fetchDetails = React.useCallback(async () => {
		setLoading(true);
		const orderId = id ?? "";

		await loadAllDetails(orderId, "Bus").finally(() => setLoading(false));
	}, [id, loadAllDetails]);

	React.useEffect(() => {
		fetchDetails();
	}, [fetchDetails]);

	const breadcrumbs = getBreadcrumbs(t);

	const { isAuthorizedGroup } = useAuthStore();

	const shouldSeeAndUpdate = isAuthorizedGroup(
		groupsAllowedToUpdateForecastBillingClosedPeriodOrdersArg
	);

	const shouldSeeProductionDates = isAuthorizedGroup(ARG_DEALERS);

	return (
		<>
			<LoadingPage loading={loading} />
			{!loading && readOnlyDetails.id && (
				<>
					<Box p={1}>
						<Grid container spacing={2}>
							<Grid item xs mb={20}>
								<Box
									p={1}
									display="flex"
									justifyContent="flex-end"
									alignItems="flex-end"
									style={{ paddingRight: "0" }}
								>
									<TitleBox breadcrumbs={breadcrumbs} />
									{shouldSeeAndUpdate && <OpenOrderButtonAndModal />}
								</Box>
								<Grid container spacing={2}>
									<GeneralAccordion
										lastUpdateDetails={{ ...readOnlyDetails }}
										generalDetails={{
											readOnlyFields: {
												...(readOnlyDetails as BusReadOnlyProps),
											},
										}}
									/>
									<CommercialAccordion
										customerDetails={{
											readOnlyFields: {
												...readOnlyDetails,
												...readOnlyDetails.orderMCManagement,
											},
										}}
										mktCompanyCommercial={{
											readOnlyFields: { ...readOnlyDetails.orderMCManagement },
										}}
										mktCompanyAdditionalComments={{
											readOnlyFields: { ...readOnlyDetails.orderMCManagement },
											shouldSeeAndUpdate,
										}}
										mktCompanyDeliverySection={{
											readOnlyFields: {
												...readOnlyDetails.orderMCDelivery,
												...readOnlyDetails.orderMCManagement,
											},
										}}
										generalComment={{
											generalComment: readOnlyDetails.generalComment,
										}}
									/>
									<VehicleAccordion
										modelDetails={{
											readOnlyFields: {
												...(readOnlyDetails as BusReadOnlyProps),
											},
										}}
										vehicleIdentificationDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										variantsDetails={{
											readOnlyFields: { ...readOnlyDetails },
											loadVariants: loadVariantsBus,
										}}
										engineeringCommentsDetails={{
											engineeringComments:
												readOnlyDetails.orderMCManagement.engineeringComments,
										}}
									/>
									{shouldSeeProductionDates && (
										<ProductionAccordion
											productionDatesDetails={{
												readOnlyFields: { ...readOnlyDetails },
											}}
										/>
									)}
									<AvailabilityInvoicingDeliveryAccordion
										forecastBillingDetails={{
											readOnlyFieldsProps: {
												...readOnlyDetails.orderMCManagement,
											},
											editableFields: {
												...editableDetails.orderMCManagement,
											},
											setOrderMCManagementFieldByKeyOnChange,
										}}
									/>
								</Grid>
								<SaveBar
									onResetClick={() => fetchDetails()}
									resetLabel={t("undoChanges", { ns: "general" })}
									onSaveClick={() => handleSubmit()}
									saveLabel={t("saveChanges", { ns: "general" })}
									hasFormChanges={hasChanges}
								/>
							</Grid>
							<DetailsTimeline />
						</Grid>
					</Box>
					<ToastContainer />
				</>
			)}
			{!loading && !readOnlyDetails.id && <NoContentResult />}
		</>
	);
};

export default observer(ClosedPeriodBusDetailIndex);

import React from "react";
import { observer } from "mobx-react";
import { useStores } from "../../stores/RootStore";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { AVAILABILITY } from "../../../../../constants/Menus/OrderMenuUtils";
import { BRA_DEALER_TNE_TNO } from "../../../../../constants/AuthGroups/authGroups";
import PreFilter from "../../../../../components/PreFilter/PreFilter";
import { useTranslation } from "react-i18next";
import { ITrucksPreFilters } from "../../components/PreFilters/interfaces/ITrucksPreFilters";
import { CHASSI_AO } from "../../../../../constants/chassiAoOmFoConst";
import LineSequenceDatePreFilter from "../../../../../components/PreFilter/containers/LineSequenceDatePreFilter";
import MonthAvailabilityPreFilter from "../../../../../components/PreFilter/containers/MonthAvailabilityPreFilter";
import DeliveryProposalPreFilter from "../../../../../components/PreFilter/containers/DeliveryProposalPreFilter";
import ChassiAOPreFilter from "../../../../../components/PreFilter/containers/ChassiAOPreFilter";
import LinePreFilter, {
	LINE_PRE_FILTER_SM,
	LINE_PRE_FILTER_XS,
} from "../../../../../components/PreFilter/containers/LinePreFilter";
import RegionPreFilter, {
	REGION_PRE_FILTER_MD_LG,
	REGION_PRE_FILTER_SM,
	REGION_PRE_FILTER_XS,
} from "../../../../../components/PreFilter/containers/RegionPreFilter";
import AsWeekPreFilter, {
	ASWEEK_PRE_FILTER_MD_LG,
	ASWEEK_PRE_FILTER_SM,
	ASWEEK_PRE_FILTER_XL,
} from "../../../../../components/PreFilter/containers/AsWeekPreFilter";
import CommercialModelPreFilter, {
	COMMERCIALMODEL_PRE_FILTER_LG_XL,
	COMMERCIALMODEL_PRE_FILTER_MD,
} from "../../../../../components/PreFilter/containers/CommercialModelPreFilter";
import CustomerSASPreFilter, {
	CUSTOMERSAS_PRE_FILTER_MD_LG_XL,
} from "../../../../../components/PreFilter/containers/CustomerSASPreFilter";
import CustomerOMPreFilter from "../../../../../components/PreFilter/containers/CustomerOMPreFilter";
import { groupsAllowedToSeeRegionPreFilter } from "../../../../../constants/AuthGroups/orderListTruck";
import { GRID_MAX_SIZE } from "../../../../../constants/gridConst";

const TrucksPreFilters: React.FC<ITrucksPreFilters> = ({
	monthAvailability,
}) => {
	const { getOptions: getOptionsRegion, setIsTracbelUser } =
		useStores().preFilterRegionStore;
	const { getOptions: getOptionsLine } = useStores().preFilterLineStore;

	const {
		preFilterAsWeekStore,
		preFilterChassiAOStore,
		preFilterCommercialModelStore,
		preFilterCustomerStoreOM,
		preFilterCustomerStoreSAS,
		preFilterLineStore,
		preFilterRegionStore,
		preFilterLineSequenceDateStore,
		preFilterMonthAvailabilityStore,
		preFilterDeliveryProposalStore,
	} = useStores();

	const { isAuthorizedGroup } = useAuthStore();
	const { menu } = useStores().listStore;
	const { t } = useTranslation("order");

	const {
		applyFilters,
		hasPreFiltersApplied,
		hasFormatError,
		filters,
		removeFilter,
		cleanSpecificPreFilters,
		onUndo,
	} = useStores().filterStore;

	setIsTracbelUser(
		isAuthorizedGroup([BRA_DEALER_TNE_TNO]) && menu !== AVAILABILITY
	);

	const shouldShowRegionPreFilter =
		isAuthorizedGroup(groupsAllowedToSeeRegionPreFilter) ||
		menu === AVAILABILITY;

	let asWeekXs = GRID_MAX_SIZE - LINE_PRE_FILTER_XS;
	let commercialModelSm =
		GRID_MAX_SIZE - (LINE_PRE_FILTER_SM + ASWEEK_PRE_FILTER_SM);
	let customerSasMd =
		GRID_MAX_SIZE - (ASWEEK_PRE_FILTER_MD_LG + COMMERCIALMODEL_PRE_FILTER_MD);
	let customerSasLg = GRID_MAX_SIZE - COMMERCIALMODEL_PRE_FILTER_LG_XL;
	let customerSasXl = CUSTOMERSAS_PRE_FILTER_MD_LG_XL;

	if (shouldShowRegionPreFilter) {
		asWeekXs = GRID_MAX_SIZE - (LINE_PRE_FILTER_XS + REGION_PRE_FILTER_XS);
		commercialModelSm = GRID_MAX_SIZE;
		customerSasMd =
			GRID_MAX_SIZE -
			(REGION_PRE_FILTER_MD_LG +
				ASWEEK_PRE_FILTER_MD_LG +
				COMMERCIALMODEL_PRE_FILTER_MD);
		customerSasLg =
			GRID_MAX_SIZE -
			(ASWEEK_PRE_FILTER_MD_LG + COMMERCIALMODEL_PRE_FILTER_LG_XL);
		customerSasXl =
			GRID_MAX_SIZE - (ASWEEK_PRE_FILTER_XL + COMMERCIALMODEL_PRE_FILTER_LG_XL);
	}

	const fetchOptions = React.useCallback(() => {
		getOptionsLine();
		if (shouldShowRegionPreFilter) {
			getOptionsRegion(menu);
		}
	}, [getOptionsLine, shouldShowRegionPreFilter, getOptionsRegion, menu]);

	React.useEffect(() => {
		fetchOptions();
	}, [fetchOptions]);

	const clearFilters = React.useCallback(() => {
		cleanSpecificPreFilters();
	}, [cleanSpecificPreFilters]);

	React.useEffect(() => {
		return () => {
			clearFilters();
		};
	}, [clearFilters]);

	return (
		<PreFilter
			id="orderbook-pre-filter-edition"
			title={t("preFilters.quickFilter", { ns: "order" })}
			onUndo={onUndo}
			onSubmit={applyFilters}
			hasPreFiltersApplied={hasPreFiltersApplied}
			hasFormatError={hasFormatError}
			filters={filters}
			chassiType={CHASSI_AO}
			removeFilter={removeFilter}
		>
			<ChassiAOPreFilter
				vehicleType="Truck"
				type={CHASSI_AO}
				preFilterStore={preFilterChassiAOStore}
			/>
			<DeliveryProposalPreFilter
				preFilterStore={preFilterDeliveryProposalStore}
			/>
			<MonthAvailabilityPreFilter
				{...monthAvailability}
				preFilterStore={preFilterMonthAvailabilityStore}
			/>
			<LineSequenceDatePreFilter
				preFilterStore={preFilterLineSequenceDateStore}
			/>
			<LinePreFilter preFilterStore={preFilterLineStore} md={2} lg={2} />
			{shouldShowRegionPreFilter && (
				<RegionPreFilter preFilterStore={preFilterRegionStore} sm={7} xl={3} />
			)}
			<AsWeekPreFilter preFilterStore={preFilterAsWeekStore} xs={asWeekXs} />
			<CommercialModelPreFilter
				preFilterStore={preFilterCommercialModelStore}
				sm={commercialModelSm}
			/>
			<CustomerSASPreFilter
				preFilterStore={preFilterCustomerStoreSAS}
				md={customerSasMd}
				lg={customerSasLg}
				xl={customerSasXl}
			/>
			<CustomerOMPreFilter preFilterStore={preFilterCustomerStoreOM} />
		</PreFilter>
	);
};

export default observer(TrucksPreFilters);

import { InvoiceLocalizationProps } from "../interfaces/InvoiceLocalizationDetails";

const invoiceLocalizationModel: InvoiceLocalizationProps = {
	receiverName: "",
	exit: null,
	exitNumber: 0,
	return: null,
	returnNumber: 0,
	invoiceOperationType: "",
};

function invoiceLocalizationItemBuilder(data: InvoiceLocalizationProps) {
	if (!data) return invoiceLocalizationModel;
	return {
		...invoiceLocalizationModel,
		...data,
		receiverName: data.receiverName ?? "",
		invoiceOperationType: data.invoiceOperationType ?? "",
	} as InvoiceLocalizationProps;
}

export function invoiceLocalizationModelBuilder(
	data: Array<InvoiceLocalizationProps>
) {
	if (!data) return [];
	return data.map((x) => invoiceLocalizationItemBuilder(x));
}

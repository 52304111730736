import React from "react";
import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { DealerQuotaProposalProps } from "../interfaces/DealerQuotaProposalProps";
import {
	dealerQuotaProposalModel,
	dealerQuotaProposalModelBuilder,
} from "../models/DealerQuotaProposalModel";
import { downloadCsv } from "../../../../utils/downloadCsv";
import moment from "moment";
import { FilterProps } from "../interfaces/FilterProps";
import { filterModel } from "../models/FilterModel";
import { LineType } from "../../../../utils/GenericTypes";

configure({ enforceActions: "always" });

/* Store start */
class DealerReadStore {
	@observable filter: FilterProps = filterModel;
	@observable dateFilter: Date = moment().startOf("year").toDate();
	@observable line: LineType = "F";
	@observable filterIsValid: boolean = true;
	@observable dealerQuotaProposal: Array<DealerQuotaProposalProps> = [];
	@observable dealerQuotaProposalTotal: DealerQuotaProposalProps =
		dealerQuotaProposalModel;
	@observable loading = false;
	@observable firmPeriodWeek: number | undefined = -1;
	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action setLoading = (value: boolean) => (this.loading = value);

	@action setFilter = () => {
		this.line = this.filter.line;
		this.dateFilter = this.filter.date;
		this.getQuotaProposalByDealer();
	};

	@action setFilterByKeyOnChange = (
		fieldKey: keyof FilterProps,
		newValue: any,
		isValid: boolean = false
	) => {
		this.filter[fieldKey] = newValue;

		if ("date" === fieldKey) this.filterIsValid = isValid;
	};

	@action private setFirmPeriodWeek = () => {
		const item = this.dealerQuotaProposal
			.slice()
			.reverse()
			.find(
				(f: DealerQuotaProposalProps) =>
					f.firmPeriodWeek && f.firmPeriodWeek >= 0
			);
		this.firmPeriodWeek = item?.firmPeriodWeek;
	};

	@action getQuotaProposalByDealer = async () => {
		this.setLoading(true);
		await this.api.quotaService
			.getDealerQuotaReadOnly(this.filter.line, this.filter.date.toDateString())
			.then((response) => {
				this.dealerQuotaProposalTotal = response.data.find(
					(x: DealerQuotaProposalProps) => x.period === null
				);
				const dealerQuotaProposal = response.data.filter(
					(x: DealerQuotaProposalProps) => x.period !== null
				);
				this.dealerQuotaProposal =
					dealerQuotaProposalModelBuilder(dealerQuotaProposal);
				this.setFirmPeriodWeek();
			})
			.finally(() => this.setLoading(false));
	};

	@action getQuotaProposalOrdersCsv = async (period: any, t: any) => {
		let fileName = `${moment(period).format("YYYYMM")}${t(
			"quota"
		)}${moment().format("YYYYMMDD")}.csv`;
		this.setLoading(true);
		await this.api.quotaService
			.getCsvQuotaProposalDealerOrders(this.filter.line, period)
			.then((content: any) => downloadCsv(content, fileName))
			.finally(() => this.setLoading(false));
	};
}

/* Store end */

/* Store helpers */
const DealerReadStoreContext = React.createContext(new DealerReadStore());

/* Hook to use store in any functional component */
export const useDealerReadStore = () =>
	React.useContext(DealerReadStoreContext);

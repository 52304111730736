import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { ExportationInvoiceSectionProps } from "../../../interfaces/Sections/Europe/ExportationInvoiceSection";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../../components/FormInputs/DateForm";

const ExportationInvoiceSection: React.FC<ExportationInvoiceSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation(["order", "general"]);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.invoiceExportation.title")}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.invoiceExportation.invoiceNumber")}
					value={readOnlyFields.invoiceNumber}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.invoiceExportation.invoiceDate")}
					value={readOnlyFields.invoiceDate}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.invoiceExportation.currency")}
					value={readOnlyFields.currency}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.invoiceExportation.incoterm")}
					value={readOnlyFields.incoterm}
				/>
			</Grid>
		</>
	);
};

export default observer(ExportationInvoiceSection);

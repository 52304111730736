import { action, configure, makeObservable, observable } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { TruckEditableProps } from "../interfaces/Editable/TruckEditableDetails";
import { TruckReadOnlyProps } from "../interfaces/TruckReadOnlyDetails";
import _ from "lodash";
import {
	truckReadOnlyModel,
	truckReadOnlyModelBuilder,
} from "../models/TruckReadOnlyModel";
import { reservationExportModelBuilder } from "../models/ReservationExportModel";
import { invoicingDeliveryMarketCompanyModelBuilder } from "../models/InvoicingDeliveryMarketCompanyModel";
import { invoiceExportationModelBuilder } from "../models/InvoiceExportationModel";
import React from "react";
import {
	truckEditableModel,
	truckEditableModelBuilder,
} from "../models/Editable/TruckEditableModel";
import { exportLocalizationModelBuilder } from "../models/ExportLocalizationModel";

configure({ enforceActions: "always" });

/* Store start */
class FollowUpChlStore {
	@observable readOnlyDetails: TruckReadOnlyProps = truckReadOnlyModel;
	@observable editableDetails: TruckEditableProps = truckEditableModel;
	@observable initialEditableDetails: TruckEditableProps = truckEditableModel;
	@observable hasChanges: boolean = false;

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action resetStore = () => {
		this.readOnlyDetails = truckReadOnlyModel;
		this.editableDetails = truckEditableModel;
		this.initialEditableDetails = truckEditableModel;
		this.hasChanges = false;
	};

	@action setEditableDetailsFieldByKeyOnChange = (
		fieldKey: keyof TruckEditableProps,
		newValue: string & Date
	) => {
		this.editableDetails[fieldKey] = newValue ?? "";
		this.checkChanges();
	};

	@action setReadOnlyDetails = (data: any) => {
		this.readOnlyDetails = truckReadOnlyModelBuilder({
			...this.readOnlyDetails,
			...data,
		});
	};

	@action setEditableDetails = (data: any) => {
		this.editableDetails = truckEditableModelBuilder({
			...this.editableDetails,
			...data,
		});
		this.initialEditableDetails = truckEditableModelBuilder({
			...this.initialEditableDetails,
			...data,
		});
	};

	@action private checkChanges = () => {
		this.hasChanges = !_.isEqual(
			this.initialEditableDetails,
			this.editableDetails
		);
	};

	@action loadDetails = async (id: string) => {
		if (!id) return;
		await this.api.followUpChlService.getDetails(id).then((response) => {
			this.setReadOnlyDetails(response.data);
			this.setEditableDetails(response.data);
			this.hasChanges = false;
		});
	};

	@action loadReservationExport = async () => {
		const id = this.readOnlyDetails.id;
		if (!id) return;
		await this.api.followUpChlService
			.getReservationExportDetails(id)
			.then((response) => {
				this.setEditableDetails(response.data);
				this.readOnlyDetails.reservationExport = reservationExportModelBuilder(
					response.data
				);
			});
	};

	@action loadVariants = async () => {
		const id = this.readOnlyDetails.id;
		if (!id) return;
		await this.api.followUpChlService
			.getDetailsVariants(id)
			.then((response) => {
				this.readOnlyDetails.variants = response.data ?? [];
			});
	};

	@action loadVariantsRespec = async () => {
		const id = this.readOnlyDetails.id;
		if (!id) return;
		await this.api.followUpChlService
			.getDetailsVariantsRespecList(id)
			.then((response) => {
				this.readOnlyDetails.variantsRespec = response.data ?? [];
			});
	};

	@action loadInvoiceExportation = async () => {
		const id = this.readOnlyDetails.id;
		if (!id) return;
		await this.api.followUpChlService
			.getInvoiceExportationByOrderId(id)
			.then((response) => {
				this.readOnlyDetails.invoiceExportation =
					invoiceExportationModelBuilder(response.data);
			});
	};

	@action loadInvoicingDeliveryMarketCompany = async () => {
		const id = this.readOnlyDetails.id;
		if (!id) return;
		await this.api.followUpChlService
			.getInvoicingDeliveryMarketCompanyByOrderId(id)
			.then((response) => {
				this.readOnlyDetails.invoicingDeliveryMarketCompany =
					invoicingDeliveryMarketCompanyModelBuilder(response.data);
			});
	};

	@action loadLocalizationExport = async () => {
		const id = this.readOnlyDetails.id;
		if (!id) return;
		await this.api.orderUnitStatusService
			.getLocalizationByOrderId(id)
			.then((response) => {
				this.readOnlyDetails.exportLocalization =
					exportLocalizationModelBuilder(response.data);
			});
	};

	@action update = async () => {
		if (!this.hasChanges) return;

		await this.api.followUpChlService.update(this.editableDetails);
	};
}
/* Store end */

const FollowUpChlStoreContext = React.createContext(new FollowUpChlStore());

export const useFollowUpChlStore = () =>
	React.useContext(FollowUpChlStoreContext);

import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { Box, Grid } from "@mui/material";
import HomePageGrid from "./HomePageGrid";
import { getClosedPeriodGridColumns } from "../utils/GridUtils";
import { CLOSED_PERIOD_MENU_ARG_TRUCK } from "../../../../constants/Menus/ClosedPeriodMenuUtils";
import { useStores } from "../stores/RootStore";
import PreFilters from "./PreFilters/PreFilters";
import HomePageCsvExport from "./HomePageCsvExport";

const TruckIndex: React.FC = () => {
	const { t } = useTranslation(["order", "menu"]);
	const { setMenu, menu, reset } = useStores().listStore;

	const { i18n } = useTranslation();
	const language = Boolean(i18n.language) ? i18n.language : "en-US";

	const gridColumns = getClosedPeriodGridColumns(t, language);

	React.useEffect(() => {
		reset();
		setMenu(CLOSED_PERIOD_MENU_ARG_TRUCK);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("closedPeriod.title", { ns: "menu" })} - ${t("truck", { ns: "menu" })}`,
			main: true,
		},
	];

	return (
		<>
			{menu === CLOSED_PERIOD_MENU_ARG_TRUCK && (
				<Grid container spacing={2}>
					<Grid item lg={12}>
						<Box sx={{ display: "flex" }}>
							<TitleBox breadcrumbs={breadcrumbs} />
							<HomePageCsvExport />
						</Box>
					</Grid>
					<PreFilters />
					<Grid item lg={12}>
						{gridColumns && <HomePageGrid columns={gridColumns} />}
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default observer(TruckIndex);

import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import ExportPartnerGrid from "./ExportPartnerGrid";
import { Box, Button, Grid } from "@mui/material";
import { TitleBox } from "../../../components/TitleBox/TitleBox";
import AddIcon from "@mui/icons-material/Add";
import { ToastContainer } from "react-toastify";
import { LoadingPage } from "../../../components/LoadingPage/LoadingPage";
import { useExportPartnerStore } from "../stores/ExportPartnerStore";

const ExportPartnerIndex: React.FC = () => {
	const { t } = useTranslation("general");

	const { loading, setFormVisible, setActionLabel } = useExportPartnerStore();

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("exportPartner", { ns: "menu" })}`,
			main: true,
		},
	];

	function handleClick() {
		setFormVisible(true);
		setActionLabel("addRecord");
		return;
	}

	return (
		<>
			<Grid item lg={12}>
				<Box
					p={1}
					display="flex"
					justifyContent="flex-end"
					alignItems="flex-end"
				>
					<TitleBox breadcrumbs={breadcrumbs} />
					<Button
						variant="contained"
						color="secondary"
						onClick={() => handleClick()}
					>
						<AddIcon /> {t("addRegister")}
					</Button>
				</Box>
			</Grid>
			<Grid item lg={12}>
				<LoadingPage loading={loading} />
				<ExportPartnerGrid />
			</Grid>
			<ToastContainer />
		</>
	);
};

export default observer(ExportPartnerIndex);

import RootStore from "../../../stores/RootStore";
import { configure } from "mobx";
import { getFilterObject } from "../../../../../../utils/FilterOperations";
import BasePreFilterStore from "../../../../../../components/PreFilter/Stores/BasePreFilterStore";

configure({ enforceActions: "always" });

const fieldName = "reservations.factoryOrder";

class PreFilterFactoryOrderStore extends BasePreFilterStore {
	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	getSpecificFilter(): any {
		if (this.preFilter === "") {
			return [];
		}

		const filters = this.preFilter.replace(" ", ",");

		return [getFilterObject(fieldName, filters, "eq")];
	};
}

export default PreFilterFactoryOrderStore;

import { TFunction } from "i18next";
import { SummaryGridProps } from "../../../../../components/SummaryDraggableCard/SummaryDraggableCard";
import {
	ArgDeliveryProbabilityFirmColor,
	ArgDeliveryProbabilityNoneColor,
	ArgDeliveryProbabilityProbableColor,
	getFollowUpArgProbabilityOptions,
} from "../../../../../utils/enums/ArgDeliveryProbabilityEnum";

export interface SummaryModelProps {
	firm: number;
	probable: number;
	none: number;
}

export const summaryModelInitial = {
	firm: 0,
	probable: 0,
	none: 0,
} as SummaryModelProps;

export function summaryModelBuilder(data: any) {
	if (!data) return summaryModelInitial;

	return {
		firm: data.firm,
		probable: data.probable,
		none: data.none,
	} as SummaryModelProps;
}

export function getSumryGridProps(data: SummaryModelProps) {
	return [
		{
			value: data.firm,
			color: ArgDeliveryProbabilityFirmColor,
		},
		{
			value: data.probable,
			color: ArgDeliveryProbabilityProbableColor,
		},
		{
			value: data.none,
			color: ArgDeliveryProbabilityNoneColor,
		},
	] as SummaryGridProps[];
}

export function getSummaryDraggableData(data: SummaryModelProps) {
	let dataTransformed = [] as SummaryGridProps[];
	let estimatedMonth = 0;

	if (!data) return { dataTransformed, estimatedMonth };
	dataTransformed = dataTransformed.concat(getSumryGridProps(data));
	estimatedMonth = getEstimatedOfMonth(data.firm, data.probable);

	return { dataTransformed, estimatedMonth };
}

export function getEstimatedOfMonth(
	totalFirmSum: number,
	totalProbableSum: number
) {
	return totalFirmSum + totalProbableSum;
}

export function getHeaders(t: TFunction<"order", undefined>) {
	const deliveryProbability = getFollowUpArgProbabilityOptions(t).toReversed();
	return deliveryProbability.map((prob) => ({
		value: prob.label,
		color: prob.color,
	}));
}

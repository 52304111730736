import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Chip, Grid, Skeleton, Typography } from "@mui/material";
import { InvoiceInstructionSectionProps } from "../../interfaces/Sections/InvoiceInstructionSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../components/FormInputs/DateForm";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { groupsAllowedToSeeAllInvoiceInstructionSection } from "../../../../../constants/AuthGroups/orderDetails";

const InvoiceInstructionSection: React.FC<InvoiceInstructionSectionProps> = ({
	userIsOwner,
	readOnlyFields,
	loadInvoiceInstruction,
}) => {
	const { t } = useTranslation("order");

	const { isAuthorizedGroup } = useAuthStore();
	const userCanSeeAllInfo =
		userIsOwner ||
		isAuthorizedGroup(groupsAllowedToSeeAllInvoiceInstructionSection);

	const [loading, setLoading] = React.useState(true);

	const fetchInvoiceInstruction = React.useCallback(async () => {
		await loadInvoiceInstruction(userCanSeeAllInfo).finally(() =>
			setLoading(false)
		);
	}, [loadInvoiceInstruction, userCanSeeAllInfo]);

	React.useEffect(() => {
		fetchInvoiceInstruction();
	}, [fetchInvoiceInstruction]);

	const invoiceInstructionFormArray = [
		t("details.sections.invoiceInstruction.form.unknown"),
		t("details.sections.invoiceInstruction.form.concessao"),
		t("details.sections.invoiceInstruction.form.leasing"),
		t("details.sections.invoiceInstruction.form.direto"),
		t("details.sections.invoiceInstruction.form.finame"),
	];

	const invoiceInstructionIsDefinitiveBadge = () => {
		if (readOnlyFields.invoiceInstruction.isDefinitive === false)
			return (
				<Chip
					size="small"
					label={t("details.sections.invoiceInstruction.onHold")}
					color="secondary"
					style={{ margin: 4 }}
				/>
			);
		if (readOnlyFields.invoiceInstruction.isDefinitive === true)
			return (
				<Chip
					size="small"
					label={t("details.sections.invoiceInstruction.definitive")}
					color="primary"
					style={{ margin: 4 }}
				/>
			);
	};

	const invoiceInstructionExpiredWarn = () => {
		if (readOnlyFields.invoiceInstruction.expirationTotal) {
			return (
				<Grid item xs={12}>
					<Typography variant="subtitle2" color="red">
						<small>
							{t("details.sections.invoiceInstruction.expiredWarn", {
								days: readOnlyFields.invoiceInstruction.expirationTotal,
							})}
						</small>
					</Typography>
				</Grid>
			);
		}
	};
	return (
		<>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : (
				<>
					<Grid className="accordionSubTitle" item xs={12}>
						{t("details.sections.invoiceInstruction.title")}
						{invoiceInstructionIsDefinitiveBadge()}
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.invoiceInstruction.form.label")}
							value={
								readOnlyFields.invoiceInstruction.form
									? invoiceInstructionFormArray[
											readOnlyFields.invoiceInstruction.form
										]
									: ""
							}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.invoiceInstruction.dealerRegion")}
							value={readOnlyFields.invoiceInstruction.dealerRegion}
						/>
					</Grid>
					<Grid item xs={4}>
						<TextForm
							label={t("details.sections.invoiceInstruction.dealer")}
							value={readOnlyFields.invoiceInstruction.dealerNameWithCode}
						/>
					</Grid>
					{userCanSeeAllInfo && (
						<>
							<Grid item xs={12}>
								<TextForm
									label={t("details.sections.invoiceInstruction.customer")}
									value={readOnlyFields.invoiceInstruction.customer}
								/>
							</Grid>
							<Grid item xs={6}>
								<DateForm
									label={t("details.sections.invoiceInstruction.creationDate")}
									value={readOnlyFields.invoiceInstruction.creationDate}
								/>
							</Grid>
							<Grid item xs={6}>
								<DateForm
									label={t(
										"details.sections.invoiceInstruction.lfApprovalDate"
									)}
									value={readOnlyFields.invoiceInstruction.lfApprovalDate}
								/>
							</Grid>
							<Grid item xs={6}>
								<DateForm
									label={t(
										"details.sections.invoiceInstruction.expirationDate"
									)}
									value={readOnlyFields.invoiceInstruction.expirationDate}
								/>
							</Grid>
							<Grid item xs={6}>
								<DateForm
									label={t(
										"details.sections.invoiceInstruction.caApprovalDate"
									)}
									value={readOnlyFields.invoiceInstruction.caApprovalDate}
								/>
							</Grid>
							{invoiceInstructionExpiredWarn()}
						</>
					)}
				</>
			)}
		</>
	);
};

export default observer(InvoiceInstructionSection);

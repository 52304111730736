import React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { VehicleType } from "../../../utils/GenericTypes";
import { TextPreFilterProps } from "../Interfaces/TextPreFilterProps";
import TextPreFilter from "../components/TextPreFilter";

interface ChassiAOProps extends TextPreFilterProps {
	type: "chassi" | "chassiAO" | "chassiFO" | "chassiFOOM";
	vehicleType: VehicleType;
}

const ChassiAO: React.FC<ChassiAOProps> = (props) => {
	const { t } = useTranslation("order");
	const { type, vehicleType, xs = 12 } = props;
	return (
		<TextPreFilter
			{...props}
			xs={xs}
			label={t(`preFilters.${type}`)}
			tooltip={
				vehicleType === "Bus"
					? t("tooltips.chassiBusSearch")
					: t("tooltips.multipleSearch")
			}
			showError={true}
		/>
	);
};

export default observer(ChassiAO);

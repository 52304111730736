import { action, configure, observable } from "mobx";
import { getFilterObject } from "../../../../../../utils/FilterOperations";
import BasePreFilterStore from "../../../../../../components/PreFilter/Stores/BasePreFilterStore";
import { SelectOptionProps } from "../../../../../../utils/GenericInterfaces";
import { getQuotationStatusSASOptions } from "../../../../../../utils/SelectOptions";
import { TFunction } from "i18next";

configure({ enforceActions: "always" });

class PreFilterStatusSASStore extends BasePreFilterStore {
	@observable preFilterOptions: Array<SelectOptionProps> = [];

	getSpecificFilter(): any {
		return this.preFilter === ""
			? []
			: [[getFilterObject("statusSAS", this.preFilter, "eq")]];
	}

	@action hasPreFilter = () => this.preFilter !== "";

	@action getOptions = async (t: TFunction<"quotation", undefined>) => {
		this.preFilterOptions = getQuotationStatusSASOptions(t);
	};
}

export default PreFilterStatusSASStore;

import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import { VehicleAccordionProps } from "../../interfaces/Accordions/VehicleAccordion";
import ModelSection from "../Sections/ModelSection";
import VariantsSection from "../Sections/VariantsSection";
import VehicleIdentificationSection from "../Sections/VehicleIdentificationSection";
import EngineeringCommentsSection from "../Sections/EngineeringCommentsSection";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import { groupsAllowedToSeeAllFieldsClosedPeriodOrdersArg } from "../../../../../constants/AuthGroups/closedPeriodArgPage";

const VehicleAccordion: React.FC<VehicleAccordionProps> = (details) => {
	const { t } = useTranslation("order");

	const { isAuthorizedGroup } = useAuthStore();
	const shouldSeeAll = isAuthorizedGroup(
		groupsAllowedToSeeAllFieldsClosedPeriodOrdersArg
	);

	return (
		<AccordionCard
			id="vehicleAccordion"
			title={t("details.accordions.vehicle")}
		>
			<ModelSection {...details.modelDetails} />
			<VehicleIdentificationSection {...details.vehicleIdentificationDetails} />
			<VariantsSection {...details.variantsDetails} />
			{shouldSeeAll && (
				<EngineeringCommentsSection {...details.engineeringCommentsDetails} />
			)}
		</AccordionCard>
	);
};

export default observer(VehicleAccordion);

import { action, configure, makeObservable, observable, toJS } from "mobx";
import CtadmvenApi from "../../../../apis/CtadmvenApi";
import { TruckReadOnlyProps } from "../interfaces/TruckReadOnlyDetails";
import _ from "lodash";
import {
	truckReadOnlyModel,
	truckReadOnlyModelBuilder,
} from "../models/TruckReadOnlyModel";
import { BusReadOnlyProps } from "../interfaces/BusReadOnlyDetails";
import { FollowUpArgEditableProps } from "../interfaces/Editable/FollowUpArgEditable";
import { OrderMCManagementEditableProps } from "../interfaces/Editable/OrderMCManagementEditable";
import {
	OrderMCDeliveryCurrentDateUserType,
	OrderMCDeliveryEditableProps,
	isOrderMCDeliveryCurrentDateUserType,
} from "../interfaces/Editable/OrderMCDeliveryEditable";
import { SelectOptionProps } from "../../../../utils/GenericInterfaces";
import {
	busReadOnlyModel,
	busReadOnlyModelBuilder,
} from "../models/BusReadOnlyModel";
import {
	followUpArgAccountingEditableModelBuilder,
	followUpArgArgMarketCompanyEditableModelBuilder,
	followUpArgBusinessEditableModelBuilder,
	followUpArgEditableModel,
	followUpArgEditableModelBuilder,
	followUpArgFinancialEditableModelBuilder,
	followUpArgGeneralCommentEditableModelBuilder,
	followUpArgLogisticEditableModelBuilder,
	followUpArgEngineeringEditableModelBuilder,
} from "../models/Editable/FollowUpArgEditableModel";
import React from "react";
import { VehicleType } from "../../../../utils/GenericTypes";
import { getLabel } from "../../../../utils/PaymentTermsUtil";

configure({ enforceActions: "always" });

/* Store start */
class FollowUpArgStore {
	@observable readOnlyDetails: TruckReadOnlyProps | BusReadOnlyProps =
		truckReadOnlyModel;
	@observable editableDetails: FollowUpArgEditableProps =
		followUpArgEditableModel;
	@observable initialEditableDetails: FollowUpArgEditableProps =
		followUpArgEditableModel;

	@observable hasChanges: boolean = false;

	@observable userEmail: string = "";

	@observable dealersOptions: Array<SelectOptionProps> = [];
	@observable vehicleLocationOptions: Array<SelectOptionProps> = [];
	@observable shippingCompaniesOptions: Array<SelectOptionProps> = [];
	@observable paymentTermsOptions: Array<SelectOptionProps> = [];
	@observable paqueteOptions: Array<SelectOptionProps> = [];

	api: CtadmvenApi;

	constructor() {
		this.api = new CtadmvenApi();
		makeObservable(this);
	}

	@action resetStore = () => {
		this.readOnlyDetails = truckReadOnlyModel;
		this.editableDetails = followUpArgEditableModel;
		this.initialEditableDetails = followUpArgEditableModel;

		this.hasChanges = false;
		this.userEmail = "";
	};

	//=========================================set observables=========================================
	@action private setReadOnlyDetails = (data: any) => {
		this.readOnlyDetails = truckReadOnlyModelBuilder({
			...truckReadOnlyModel,
			...data,
		});
	};

	@action private setReadOnlyDetailsBus = (data: any) => {
		this.readOnlyDetails = busReadOnlyModelBuilder({
			...busReadOnlyModel,
			...data,
		});
	};

	@action private setEditableDetails = (data: any) => {
		const initialDataModel = followUpArgEditableModelBuilder({
			...this.editableDetails,
			...data,
		});

		this.editableDetails = initialDataModel;
		this.initialEditableDetails = initialDataModel;
	};

	@action setUserEmail = (userEmail: string) => (this.userEmail = userEmail);

	@action private setDealersOptions = (data: any) => {
		this.dealersOptions = [];

		if (!data) return;

		const arrayOfKeyValues = Object.entries(data);
		this.dealersOptions = arrayOfKeyValues.map((m) => ({
			value: m[0],
			label: m[1] as string,
		}));
	};

	@action private setVehicleLocationOptions = (data: any) => {
		this.vehicleLocationOptions = [];

		if (!data) return;

		const arrayOfKeyValues = Object.entries(data);
		this.vehicleLocationOptions = arrayOfKeyValues.map((m) => ({
			value: m[0],
			label: m[1] as string,
		}));
	};

	@action private setShippingCompaniesOptions = (data: any) => {
		this.shippingCompaniesOptions = [];

		if (!data) return;

		this.shippingCompaniesOptions = data.map((m: string) => ({
			value: m,
			label: m,
		}));
	};

	@action private setPaymentTermsOptions = (data: any, language: string) => {
		this.paymentTermsOptions = [];

		if (!data) return;

		this.paymentTermsOptions = toJS(data).map(
			(m: any) =>
				({
					label: getLabel(m, language),
					value: m.id,
				}) as SelectOptionProps
		);
	};

	@action private setPaqueteOptions = (data: any) => {
		this.paqueteOptions = [];

		if (!data) return;

		this.paqueteOptions = toJS(data).map(
			(m: any) =>
				({
					label: m.paqueteDescription,
					value: m.id,
				}) as SelectOptionProps
		);
	};
	//END: observables

	//=========================================set fields on change=========================================
	@action setFieldByKeyOnChange = <K extends keyof FollowUpArgEditableProps>(
		fieldKey: K,
		newValue: FollowUpArgEditableProps[K],
		hasErrors?: boolean
	) => {
		if (hasErrors) return;
		let _editableDetails: FollowUpArgEditableProps = Object.assign(
			{},
			this.editableDetails
		);
		_editableDetails[fieldKey] = newValue;

		this.editableDetails = _editableDetails;
		this.checkChanges();
	};

	@action setOrderMCDeliveryFieldByKeyOnChange = <
		K extends keyof OrderMCDeliveryEditableProps,
	>(
		fieldKey: K,
		newValue: OrderMCDeliveryEditableProps[K],
		hasErrors?: boolean
	) => {
		if (hasErrors) return;
		let _editableDetails: OrderMCDeliveryEditableProps = Object.assign(
			{},
			this.editableDetails.orderMCDelivery
		);
		_editableDetails[fieldKey] = newValue;

		if (isOrderMCDeliveryCurrentDateUserType(fieldKey)) {
			_editableDetails = this.setOrderMCDeliveryCurrentUser(
				fieldKey,
				_editableDetails,
				newValue !== null
			);
			_editableDetails =
				this.setOrderMCDeliveryCurrentFinalDelivery(_editableDetails);
		}

		this.editableDetails.orderMCDelivery = _editableDetails;
		this.checkChanges();
	};

	@action private setOrderMCDeliveryCurrentUser = (
		fieldKey: OrderMCDeliveryCurrentDateUserType,
		_editableDetails: OrderMCDeliveryEditableProps,
		hasValue: boolean
	) => {
		if (hasValue) {
			_editableDetails[`${fieldKey}User`] = this.userEmail;
		} else {
			_editableDetails[`${fieldKey}User`] = "";
		}
		return _editableDetails;
	};

	@action private setOrderMCDeliveryCurrentFinalDelivery = (
		_editableDetails: OrderMCDeliveryEditableProps
	) => {
		if (
			_editableDetails.firmBilling &&
			_editableDetails.financialDelivery &&
			_editableDetails.physicalDelivery
		) {
			_editableDetails.finalDelivery = _editableDetails.physicalDelivery;
			_editableDetails.finalDeliveryUser =
				_editableDetails.physicalDeliveryUser;
		} else {
			_editableDetails.finalDelivery = null;
			_editableDetails.finalDeliveryUser = "";
		}
		return _editableDetails;
	};

	@action setOrderMCManagementFieldByKeyOnChange = <
		K extends keyof OrderMCManagementEditableProps,
	>(
		fieldKey: K,
		newValue: OrderMCManagementEditableProps[K],
		hasErrors?: boolean
	) => {
		if (hasErrors) return;
		const _editableDetails: OrderMCManagementEditableProps = Object.assign(
			{},
			this.editableDetails.orderMCManagement
		);
		_editableDetails[fieldKey] = newValue;
		this.editableDetails.orderMCManagement = _editableDetails;
		this.checkChanges();
	};

	@action private checkChanges = () => {
		this.hasChanges = !_.isEqual(
			this.initialEditableDetails,
			this.editableDetails
		);
	};

	//END: set fields on change

	//=========================================load details=========================================
	@action loadAllDetails = async (
		orderId: string,
		userEmail: string,
		vehicleType: VehicleType,
		userCanEditDealer: boolean,
		userCanEditVehicleLocation: boolean,
		userCanEditShippingCompany: boolean,
		userCanEditPaymentForm: boolean,
		language: string
	) => {
		this.resetStore();

		this.setUserEmail(userEmail);

		if (vehicleType === "Truck") await this.loadDetails(orderId);
		else await this.loadDetailsBus(orderId);

		await this.loadMktCompanyManagementAllData(
			userCanEditDealer,
			userCanEditVehicleLocation,
			userCanEditShippingCompany,
			userCanEditPaymentForm,
			language
		);
		await this.loadOrderMktCompanyDelivery();
	};

	@action private loadDetails = async (orderId: string) => {
		if (!orderId) return;
		await this.api.followUpArgService.getDetails(orderId).then((response) => {
			this.setReadOnlyDetails(response.data);
			this.setEditableDetails(response.data);
		});
	};

	@action private loadDetailsBus = async (orderId: string) => {
		if (!orderId) return;
		await this.api.followUpArgService
			.getDetailsBus(orderId)
			.then((response) => {
				this.setReadOnlyDetailsBus(response.data);
				this.setEditableDetails(response.data);
			});
	};

	@action loadVariants = async () => {
		const orderId = this.readOnlyDetails.id;
		if (!orderId) return;
		await this.api.followUpArgService
			.getDetailsVariants(orderId)
			.then((response) => {
				this.readOnlyDetails.variants = response.data ?? [];
			});
	};

	@action loadVariantsBus = async () => {
		const orderId = this.readOnlyDetails.id;
		if (!orderId) return;
		await this.api.followUpArgService
			.getDetailsVariantsBus(orderId)
			.then((response) => {
				this.readOnlyDetails.variants = response.data ?? [];
			});
	};

	@action private loadOrderMktCompanyDelivery = async () => {
		const orderId = this.readOnlyDetails.id;
		if (!orderId) return;
		await this.api.followUpArgService
			.getDetailsMCDelivery(orderId)
			.then((response) => {
				this.setEditableDetails({
					...this.editableDetails,
					orderMCDelivery: response.data,
				});
			});
	};

	@action private loadDealers = async (userCanEditDealer: boolean) => {
		const defaultDealer = {
			label: this.readOnlyDetails.dealerName,
			value: this.editableDetails.orderMCManagement.dealerId,
		};

		if (!userCanEditDealer) return (this.dealersOptions = [defaultDealer]);

		await this.api.dealerService
			.getDictionaryIdNameByCountry("ARG")
			.then((response) => {
				this.setDealersOptions(response.data);
			});
	};

	@action private loadVehicleLocations = async (
		userCanEditVehicleLocation: boolean
	) => {
		const defaultVehicleLocation = {
			label: this.readOnlyDetails.vehicleLocationName,
			value: this.editableDetails.orderMCManagement.vehicleLocationId,
		};

		if (!userCanEditVehicleLocation)
			return (this.vehicleLocationOptions = [defaultVehicleLocation]);

		await this.api.vehicleLocationService
			.getDictionaryIdNameByCountry("ARG")
			.then((response) => {
				this.setVehicleLocationOptions(response.data);
			});
	};

	@action private loadShippingCompanies = async (
		userCanEditShippingCompany: boolean
	) => {
		const defaultShippingCompany = {
			label: this.editableDetails.orderMCManagement.shippingCompany,
			value: this.editableDetails.orderMCManagement.shippingCompany,
		};

		if (!userCanEditShippingCompany)
			return (this.shippingCompaniesOptions = [defaultShippingCompany]);

		await this.api.followUpArgService
			.getShippingCompanies()
			.then((response) => {
				this.setShippingCompaniesOptions(response.data);
			});
	};

	@action private loadPaymentTerms = async (
		userCanEditPaymentForm: boolean,
		language: string
	) => {
		const defaultPaymentTerm = {
			descriptionEsEs: this.readOnlyDetails.paymentFormEsEs,
			descriptionEnUs: this.readOnlyDetails.paymentFormEnUs,
			descriptionPtBr: this.readOnlyDetails.paymentFormPtBr,
			id: this.editableDetails.orderMCManagement.paymentForm,
		};

		if (!userCanEditPaymentForm)
			return this.setPaymentTermsOptions([defaultPaymentTerm], language);

		await this.api.paymentTermsService
			.getPaymentTerms("ARG")
			.then((rs: any) => {
				if (rs.data) this.setPaymentTermsOptions(rs.data, language);
			});
	};

	@action private loadPaquetes = async () => {
		await this.api.paqueteService.getPaqueteList().then((rs: any) => {
			if (rs.data) this.setPaqueteOptions(rs.data);
		});
	};

	@action private loadOrderMktCompanyManagement = async () => {
		const orderId = this.readOnlyDetails.id;
		if (!orderId) return;

		await this.api.followUpArgService
			.getDetailsMCManagement(orderId)
			.then((response) => {
				this.setReadOnlyDetails({ ...this.readOnlyDetails, ...response.data });
				this.setEditableDetails({
					...this.editableDetails,
					orderMCManagement: response.data,
				});
			});
	};

	@action private loadMktCompanyManagementAllData = async (
		userCanEditDealer: boolean,
		userCanEditVehicleLocation: boolean,
		userCanEditShippingCompany: boolean,
		userCanEditPaymentForm: boolean,
		language: string
	) => {
		await this.loadOrderMktCompanyManagement();
		await this.loadDealers(userCanEditDealer);
		await this.loadVehicleLocations(userCanEditVehicleLocation);
		await this.loadShippingCompanies(userCanEditShippingCompany);
		await this.loadPaymentTerms(userCanEditPaymentForm, language);
		await this.loadPaquetes();
	};
	//END: load details

	@action update = async (userUpdatePermission: string) => {
		if (!this.hasChanges) return;

		switch (userUpdatePermission) {
			case "UpdateFollowUpArg":
				return await this.api.followUpArgService.update(
					followUpArgArgMarketCompanyEditableModelBuilder(this.editableDetails)
				);
			case "UpdateFollowUpArgGeneralComment":
				return await this.api.followUpArgService.updateGeneralComment(
					followUpArgGeneralCommentEditableModelBuilder(this.editableDetails)
				);
			case "UpdateFollowUpArgFinancial":
				return await this.api.followUpArgService.updateAsFinancial(
					followUpArgFinancialEditableModelBuilder(this.editableDetails)
				);
			case "UpdateFollowUpArgLogistic":
				return await this.api.followUpArgService.updateAsLogistic(
					followUpArgLogisticEditableModelBuilder(this.editableDetails)
				);
			case "UpdateFollowUpArgAccounting":
				return await this.api.followUpArgService.updateAsAccounting(
					followUpArgAccountingEditableModelBuilder(this.editableDetails)
				);
			case "UpdateFollowUpArgBusiness":
				return await this.api.followUpArgService.updateAsBusiness(
					followUpArgBusinessEditableModelBuilder(this.editableDetails)
				);
			case "UpdateFollowUpArgEngineering":
				return await this.api.followUpArgService.updateAsEngineering(
					followUpArgEngineeringEditableModelBuilder(this.editableDetails)
				);
			default:
				return;
		}
	};
}
/* Store end */
const FollowUpArgStoreContext = React.createContext(new FollowUpArgStore());

export const useFollowUpArgStore = () =>
	React.useContext(FollowUpArgStoreContext);

import RootStore from "../../../stores/RootStore";
import { action, configure, observable } from "mobx";
import {
	OrderBookMenuType,
	TOTAL,
} from "../../../../../../constants/Menus/OrderMenuUtils";
import BasePreFilterStore from "../../../../../../components/PreFilter/Stores/BasePreFilterStore";
import { getFilterObjectArrayOfStringKeyBySize } from "../../../../../../utils/FilterOperations";

configure({ enforceActions: "always" });

class PreFilterCommercialModelStore extends BasePreFilterStore {
	filterKey = "commercialModel";
    filterSize = 50;
    operator = "contains";

	@observable menu: OrderBookMenuType = TOTAL;

	constructor(rootStore: RootStore) {
		super(rootStore);
	}

	@action setMenu = (value: OrderBookMenuType) => (this.menu = value);

	getSpecificFilter(): any {
		return getFilterObjectArrayOfStringKeyBySize(
			this.filterSize,
			this.preFilter,
			this.filterKey,
			this.operator
		);
	};
}

export default PreFilterCommercialModelStore;

import {
	CTADMVEN_ORDER_EXTERNAL_BRAZIL,
	CTADMVEN_ORDER_MKT_COMPANY,
	CTADMVEN_ORDER_AVAILABILITY,
	CTADMVEN_ORDER_INTERNAL_VDB,
	CTADMVEN_ORDER_TOTAL,
	CTADMVEN_ORDER_BUS_TOTAL,
	CTADMVEN_ORDER_BUS_BRAZIL,
	CTADMVEN_ORDER_BUS_PI,
	CTADMVEN_ORDER_BUS_MKT_COMPANY_TOTAL,
	CTADMVEN_ORDER_BUS_MEAC,
	CTADMVEN_ORDER_BUS_FREE_UNIT_VCI,
	CTADMVEN_ORDER_FD_MKT_COMPANY,
	CTADMVEN_ORDER_EUROPE_FACTORY_ORDERBOOK,
	CTADMVEN_ORDER_FD_MKT_COMPANY_BUS,
	CTADMVEN_ORDER_PRIVATE_IMPORTERS,
	CTADMVEN_ORDER_VCI,
	CTADMVEN_ORDER_FD_MKT_COMPANY_EUROPE,
	CTADMVEN_ORDER_BUS_FREE_UNIT_VCI_VDB,
} from "../routes";

//constants
export const TOTAL = "total";
export const INTERNAL_VDB = "internal";
export const EXTERNAL_BRAZIL = "external";
export const AVAILABILITY = "availability";
export const MKT_COMPANY = "mktCompany";
export const PRIVATE_IMPORTERS = "pi";
export const VCI = "vci";
export const EUROPE_FACTORY_ORDERBOOK = "europeOrderBook";
export const BUS_TOTAL = "busTotal";
export const BUS_BRAZIL = "busBrazil";
export const BUS_FREE_UNIT_VCI = "busFreeUnitVCI";
export const BUS_FREE_UNIT_VCI_VDB = "busFreeUnitVCIVDB";
export const BUS_MEAC = "busMEAC";
export const BUS_MKT_COMPANY = "busMktCompany";
export const BUS_PI = "busPI";
export const FD_MKT_COMPANY = "fdMktCompany";
export const FD_MKT_COMPANY_BUS = "fdMktCompanyBus";
export const FD_MKT_COMPANY_EUROPE = "fdMktCompanyEurope";

export const BusMenus: OrderBookBusMenuType[] = [
	BUS_TOTAL,
	BUS_BRAZIL,
	BUS_PI,
	BUS_MKT_COMPANY,
	BUS_MEAC,
	BUS_FREE_UNIT_VCI,
	BUS_FREE_UNIT_VCI_VDB,
	FD_MKT_COMPANY_BUS,
];
export const TruckBrMenus: OrderBookMenuType[] = [
	TOTAL,
	INTERNAL_VDB,
	EXTERNAL_BRAZIL,
	MKT_COMPANY,
	AVAILABILITY,
	FD_MKT_COMPANY,
	PRIVATE_IMPORTERS,
	VCI,
];
const TruckEuropeMenus: OrderBookMenuType[] = [
	EUROPE_FACTORY_ORDERBOOK,
	FD_MKT_COMPANY_EUROPE,
];
export const TruckMenus: OrderBookMenuType[] = [
	...TruckBrMenus,
	...TruckEuropeMenus,
];
export const MarketCompanyMenus: OrderBookMenuType[] = [
	MKT_COMPANY,
	FD_MKT_COMPANY,
	BUS_MKT_COMPANY,
	FD_MKT_COMPANY_BUS,
	FD_MKT_COMPANY_EUROPE,
];
export const EuropeMenus: OrderBookMenuType[] = [
	EUROPE_FACTORY_ORDERBOOK,
	FD_MKT_COMPANY_EUROPE,
];
export const FactoryDeliveryMenus: OrderBookMenuType[] = [
	FD_MKT_COMPANY,
	FD_MKT_COMPANY_BUS,
	FD_MKT_COMPANY_EUROPE,
];
export const TruckMarketCompanyMenus: OrderBookMenuType[] = [
	MKT_COMPANY,
	FD_MKT_COMPANY,
	PRIVATE_IMPORTERS,
];

//types
export type OrderBookTruckMenuType =
	| typeof TOTAL
	| typeof INTERNAL_VDB
	| typeof EXTERNAL_BRAZIL
	| typeof MKT_COMPANY
	| typeof FD_MKT_COMPANY
	| typeof AVAILABILITY
	| typeof EUROPE_FACTORY_ORDERBOOK
	| typeof PRIVATE_IMPORTERS
	| typeof VCI
	| typeof FD_MKT_COMPANY_EUROPE;

export type OrderBookBusMenuType =
	| typeof BUS_TOTAL
	| typeof BUS_BRAZIL
	| typeof BUS_PI
	| typeof BUS_MKT_COMPANY
	| typeof BUS_MEAC
	| typeof BUS_FREE_UNIT_VCI
	| typeof BUS_FREE_UNIT_VCI_VDB
	| typeof FD_MKT_COMPANY_BUS;

export type OrderBookMenuType = OrderBookTruckMenuType | OrderBookBusMenuType;

//utils
export function getMenuTruckByRoute(routeMenu: string) {
	if (CTADMVEN_ORDER_EXTERNAL_BRAZIL.includes(routeMenu))
		return EXTERNAL_BRAZIL;
	if (CTADMVEN_ORDER_TOTAL.includes(routeMenu)) return TOTAL;
	if (CTADMVEN_ORDER_INTERNAL_VDB.includes(routeMenu)) return INTERNAL_VDB;
	if (CTADMVEN_ORDER_MKT_COMPANY.includes(routeMenu)) return MKT_COMPANY;
	if (CTADMVEN_ORDER_AVAILABILITY.includes(routeMenu)) return AVAILABILITY;
	if (CTADMVEN_ORDER_FD_MKT_COMPANY.includes(routeMenu)) return FD_MKT_COMPANY;
	if (CTADMVEN_ORDER_EUROPE_FACTORY_ORDERBOOK.includes(routeMenu))
		return EUROPE_FACTORY_ORDERBOOK;
	if (CTADMVEN_ORDER_PRIVATE_IMPORTERS.includes(routeMenu))
		return PRIVATE_IMPORTERS;
	if (CTADMVEN_ORDER_VCI.includes(routeMenu)) return VCI;
	if (CTADMVEN_ORDER_FD_MKT_COMPANY_EUROPE.includes(routeMenu))
		return FD_MKT_COMPANY_EUROPE;
	return "";
}

export function getMenuBusByRoute(routeMenu: string) {
	if (CTADMVEN_ORDER_BUS_TOTAL.includes(routeMenu)) return BUS_TOTAL;
	if (CTADMVEN_ORDER_BUS_BRAZIL.includes(routeMenu)) return BUS_BRAZIL;
	if (CTADMVEN_ORDER_BUS_PI.includes(routeMenu)) return BUS_PI;
	if (CTADMVEN_ORDER_BUS_MKT_COMPANY_TOTAL.includes(routeMenu))
		return BUS_MKT_COMPANY;
	if (CTADMVEN_ORDER_BUS_MEAC.includes(routeMenu)) return BUS_MEAC;
	if (CTADMVEN_ORDER_BUS_FREE_UNIT_VCI_VDB.endsWith(routeMenu))
		return BUS_FREE_UNIT_VCI_VDB;
	if (CTADMVEN_ORDER_BUS_FREE_UNIT_VCI.endsWith(routeMenu))
		return BUS_FREE_UNIT_VCI;
	if (CTADMVEN_ORDER_FD_MKT_COMPANY_BUS.includes(routeMenu))
		return FD_MKT_COMPANY_BUS;
	return "";
}

export function getMenuTitleByMenuKey(menuKey: OrderBookMenuType | "") {
	switch (menuKey) {
		case BUS_TOTAL:
		case BUS_BRAZIL:
		case BUS_PI:
		case BUS_MKT_COMPANY:
		case BUS_MEAC:
		case BUS_FREE_UNIT_VCI:
		case BUS_FREE_UNIT_VCI_VDB:
			return "orderBookBus.orderBook";

		case FD_MKT_COMPANY:
		case FD_MKT_COMPANY_EUROPE:
			return "orderBookFactoryDeliveries.orderBook";

		case FD_MKT_COMPANY_BUS:
			return "orderBookFactoryDeliveriesBus.orderBook";

		case EUROPE_FACTORY_ORDERBOOK:
			return "europeFactory.title";

		default:
			return "orderBookTrucks.orderBook";
	}
}

import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../../components/FormInputs/DateForm";
import {
	getBooleanOptions,
	getExportationStatusOptions,
} from "../../../../../../utils/SelectOptions";
import SelectForm from "../../../../../../components/FormInputs/SelectForm";
import { ReservationExportSectionProps } from "../../../interfaces/Sections/Europe/ReservationExportSection";

const ReservationExportSection: React.FC<ReservationExportSectionProps> = ({
	readOnlyFields,
}) => {
	const { t } = useTranslation(["order", "general"]);

	const booleanOptions = getBooleanOptions(t);
	const exportationOptions = getExportationStatusOptions(t).filter(
		(x) => x.value !== "InvoicedMC"
	);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.reservationExport.title")}
			</Grid>
			<Grid item xs={2}>
				<SelectForm
					label={t("details.sections.reservationExport.available")}
					labelId={"sections.reservationExport-available-label"}
					value={readOnlyFields.available}
					options={booleanOptions}
				/>
			</Grid>
			<Grid item xs={5}>
				<SelectForm
					label={t("details.sections.reservationExport.stockStatus")}
					labelId={t("sections.reservationExport-stockStatus-label")}
					value={readOnlyFields.exportationStatus}
					options={exportationOptions}
				/>
			</Grid>
			<Grid item xs={5}>
				<DateForm
					label={t("details.sections.reservationExport.availabilityDate")}
					value={readOnlyFields.availableDate}
					inputFormat="dd/MM/yyyy"
				/>
			</Grid>
			<Grid item xs={2}>
				<SelectForm
					label={t("details.sections.reservationExport.reserved")}
					labelId={"sections.reservationExport-reserved-label"}
					value={readOnlyFields.reserved}
					options={booleanOptions}
				/>
			</Grid>
			<Grid item xs={5}>
				<TextForm
					label={t("details.sections.reservationExport.quotationNumber")}
					value={readOnlyFields.quotationNumber}
				/>
			</Grid>
			<Grid item xs={5}>
				<DateForm
					label={t(
						"details.sections.reservationExport.reservationCreationDate"
					)}
					value={readOnlyFields.reservationCreationDate}
					inputFormat="dd/MM/yyyy"
				/>
			</Grid>
		</>
	);
};

export default observer(ReservationExportSection);

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import moment from "moment";
import { range } from "lodash";
import SelectForm from "../FormInputs/SelectForm";
import { getNumbersOptions } from "../../utils/SelectOptions";

interface YearFilterProps {
	value: number;
	set: (value: any) => void;
}

const YearFilter: React.FC<YearFilterProps> = ({
	set,
	value = moment().startOf("year").year(),
}) => {
	const { t } = useTranslation("general");
	const [year, setYear] = useState<number>(value);

	const maxYear = moment().year() + 3;
	const minYear = moment().year() - 2;
	const yearsFilterArray = range(minYear, maxYear);
	const yearsFilterOption = getNumbersOptions(yearsFilterArray);

	const handleChange = (event: any) => {
		const value = event.target.value;
		setYear(value);

		if (!Boolean(validator(value))) {
			set({ value: value, isValid: true });
		} else {
			set({ value: undefined, isValid: false });
		}
	};

	const validator = (value: string) => {
		if (!yearsFilterArray.includes(+value)) return "required";
		return "";
	};

	return (
		<SelectForm
			editable
			label={t("year")}
			labelId={"filter-year-label"}
			value={year}
			options={yearsFilterOption}
			onChange={handleChange}
			validator={validator}
		/>
	);
};

export default observer(YearFilter);

import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import AccordionCard from "../../../../../components/AccordionCard/AccordionCard";
import { ProductionAccordionProps } from "../../interfaces/Accordions/ProductionAccordion";
import ProductionDatesSection from "../Sections/ProductionDatesSection";
import RespecificationSection from "../Sections/RespecificationSection";
import VariantsRespecificationSection from "../Sections/VariantsRespecificationSection";

const ProductionAccordion: React.FC<ProductionAccordionProps> = (details) => {
	const { t } = useTranslation("order");

	return (
		<AccordionCard
			id="productionAccordion"
			title={t("details.accordions.production")}
		>
			<ProductionDatesSection {...details.productionDatesDetails} />
			<RespecificationSection {...details.respecificationDetails} />
			<VariantsRespecificationSection
				{...details.variantsRespecificationDetails}
			/>
		</AccordionCard>
	);
};

export default observer(ProductionAccordion);

import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid, Skeleton } from "@mui/material";
import { VariantsSectionProps } from "../../interfaces/Sections/VariantsSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import { VariantProps } from "../../interfaces/VariantDetails";
import OverlineTextCentered from "../../../../../components/OverlineTextCentered/OverlineTextCentered";
import { getLabel } from "../../../../../utils/VariantsUtil";

const VariantsSection: React.FC<VariantsSectionProps> = ({
	readOnlyFields,
	loadVariants,
}) => {
	const { t } = useTranslation(["order", "general"]);
	const [loading, setLoading] = React.useState(true);
	const { i18n } = useTranslation();
	const language = Boolean(i18n.language) ? i18n.language : "en-US";

	const fetchVariants = React.useCallback(async () => {
		await loadVariants().finally(() => setLoading(false));
	}, [loadVariants]);

	React.useEffect(() => {
		fetchVariants();
	}, [fetchVariants]);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.variants.title")}
			</Grid>
			{loading ? (
				<Grid item xs={12}>
					<Skeleton variant="rounded" width="100%" height={60} />
				</Grid>
			) : readOnlyFields.variants?.length > 0 ? (
				readOnlyFields.variants.map((variant: VariantProps, idx: number) => (
					<Grid key={idx} item xs={6}>
						<TextForm
							label={getLabel(variant, language)}
							value={variant.value}
						/>
					</Grid>
				))
			) : (
				<Grid item xs={12}>
					<OverlineTextCentered>
						{t("noRecordsAvailable", { ns: "general" })}
					</OverlineTextCentered>
				</Grid>
			)}
		</>
	);
};

export default observer(VariantsSection);

import { Grid } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import { LoadingPage } from "../../../../components/LoadingPage/LoadingPage";
import { TitleBox } from "../../../../components/TitleBox/TitleBox";
import { useVariantsStore } from "../stores/VariantsStore";
import EditGrid from "./EditGrid";
import { getUserUpdatePermission } from "../utils/EditableFieldsUtils";
import { useAuthStore } from "../../../../auth/store/AuthStore";

const VariantsIndex: React.FC = () => {
	const { t } = useTranslation("general");
	const {
		loading,
		variants,
		fetchData,
		setUserUpdatePermission,
		userUpdatePermission,
	} = useVariantsStore();
	const { isAuthorizedGroup } = useAuthStore();

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: `${t("variants", { ns: "menu" })}`,
			main: true,
		},
	];

	const loadPage = React.useCallback(() => {
		fetchData().catch((err) => toast.error(err.response));
		setUserUpdatePermission(getUserUpdatePermission(isAuthorizedGroup));
	}, [fetchData, isAuthorizedGroup, setUserUpdatePermission]);
	React.useEffect(() => {
		loadPage();
	}, [loadPage]);

	return (
		<>
			<LoadingPage loading={loading} />
			<Grid item lg={12}>
				<TitleBox breadcrumbs={breadcrumbs} />
			</Grid>
			<Grid item lg={12}>
				{variants && variants.length > 0 && userUpdatePermission && (
					<EditGrid />
				)}
			</Grid>
			<ToastContainer />
		</>
	);
};

export default observer(VariantsIndex);
